import React from 'react';

import Button from 'components/Button';

import logoImg from 'assets/logo.png';
import instagramImg from 'assets/instagram.png';
import youtubeImg from 'assets/youtube.png';
import facebookImg from 'assets/facebook.png';

const Footer = () => {
  return (
    <div className="w-full pt-10 p-5 bg-light-green">
      <div className="flex gap-x-20 mx-auto max-w-8xl">
        <div className="flex flex-col items-center justify-between w-1/5">
          <img
            className="w-36 h-11"
            src={logoImg}
            alt="header-logo"
          />
          <p className="text-sm text-gray-500">Copyright @Careplatform 2023</p>
        </div>
        <div className="flex-1">
          <div className="flex gap-x-10">
            <div className="flex justify-center w-full">
              <div className="w-fit">
                <p className="font-semibold">Book an appointment</p>
                <div className="flex flex-col gap-y-5 mt-6 text-sm">
                  <p className="cursor-pointer">Find Providers</p>
                  <p className="cursor-pointer">Find Specialists</p>
                  <p className="cursor-pointer">Provider Sign Up / Login</p>
                  <p className="cursor-pointer">Clinic Hospital Sign Up / Login</p>
                </div>
              </div>
            </div>
            <div className="flex justify-center w-full">
              <div className="w-fit">
                <p className="font-semibold">Company</p>
                <div className="flex flex-col gap-y-5 mt-6 text-sm">
                  <p className="cursor-pointer">About Us</p>
                  <p className="cursor-pointer">Mobile App</p>
                  <p className="cursor-pointer">Career</p>
                  <p className="cursor-pointer">Contact</p>
                </div>
              </div>
            </div>
            <div className="flex justify-center w-full">
              <div className="w-fit">
                <p className="font-semibold">Social Media</p>
                <div className="flex flex-col gap-y-5 mt-6 text-sm">
                  <p className="cursor-pointer">Instagram</p>
                  <p className="cursor-pointer">Twitter</p>
                  <p className="cursor-pointer">Youtube</p>
                  <p className="cursor-pointer">Facebook</p>
                </div>
              </div>
            </div>
            <div className="w-full">
              <Button cssClass="ml-auto px-10">Contact Us</Button>
            </div>
          </div>
          <div className="flex items-end justify-between pt-12 pb-3 border-b">
            <div className="flex items-end gap-x-2">
              <p className="font-semibold">Give Us a Call</p>
              <p className="text-2xl font-semibold">(+1) 8111-874-512</p>
            </div>
            <div className="flex gap-x-8">
              <div className="text-gray-500">Privacy</div>
              <div className="text-gray-500">Terms</div>
            </div>
          </div>
          <div className="flex items-center justify-end gap-x-2 pt-5">
            <div className="p-2 rounded-full bg-shade-green cursor-pointer">
              <img
                className="w-5 h-5"
                src={instagramImg}
              />
            </div>
            <div className="p-2 rounded-full bg-shade-green cursor-pointer">
              <img
                className="w-5 h-5"
                src={facebookImg}
              />
            </div>
            <div className="p-2 rounded-full bg-shade-green cursor-pointer">
              <img
                className="w-5 h-5"
                src={youtubeImg}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
