import React from 'react';
import { BsExclamationCircle } from 'react-icons/bs';

import Input from 'components/Input';
import Button from 'components/Button';
import Select from 'components/Select';

const Credits = () => {
  return (
    <div className="p-8 h-full">
      <p className="text-2xl font-semibold">Credits</p>
      <div className="flex flex-col gap-y-4 mt-4">
        <div className="py-5 px-5 bg-white rounded-xl">
          <p className="text-lg font-medium">Add bank accounts</p>
          <form className="flex flex-col gap-y-5 mt-4">
            <div className="grid grid-cols-2 gap-4">
              <Select
                options={[{ value: 'bank', label: 'Bank' }]}
                placeholder="Your bank"
              />
              <div className="flex items-start gap-x-2">
                <div className="py-0.5">
                  <BsExclamationCircle color="#08A284" />
                </div>
                <div className="text-sm">
                  <p className="font-semibold">Your credit balance</p>
                  <p>Credits are automotically applied to consultation</p>
                </div>
              </div>
              <Input
                name="accountNumber"
                placeholder="Account's number"
              />
              <Input
                name="holdername"
                placeholder="Account holder's name"
              />
            </div>
            <Button rounded={false}>Add Bank Account</Button>
          </form>
        </div>
        <div className="py-5 px-5 bg-white rounded-xl">
          <p className="text-lg font-medium">Credit transaction history</p>
          <div className="flex flex-col gap-y-1 mt-4">
            <div className="flex items-center gap-x-4">
              <p className="basis-1/3 text-main-green font-semibold">Date</p>
              <p className="basis-1/3 text-main-green font-semibold">Paid to Provider</p>
              <p className="basis-1/3 text-main-green font-semibold">Amount</p>
            </div>
            <div className="flex items-center gap-x-4">
              <p className="basis-1/3">2023-10-13 at 08:24 AM</p>
              <p className="basis-1/3">$0.00</p>
              <p className="basis-1/3">$0.00</p>
            </div>
            <div className="flex items-center gap-x-4">
              <p className="basis-1/3">2023-10-13 at 08:24 AM</p>
              <p className="basis-1/3">$0.00</p>
              <p className="basis-1/3">$0.00</p>
            </div>
            <div className="flex items-center gap-x-4">
              <p className="basis-1/3">2023-10-13 at 08:24 AM</p>
              <p className="basis-1/3">$0.00</p>
              <p className="basis-1/3">$0.00</p>
            </div>
            <div className="flex items-center gap-x-4">
              <p className="basis-1/3">2023-10-13 at 08:24 AM</p>
              <p className="basis-1/3">$0.00</p>
              <p className="basis-1/3">$0.00</p>
            </div>
          </div>
        </div>
        <Button rounded={false}>Export to PDF/Excel</Button>
      </div>
    </div>
  );
};

export default Credits;
