import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Input from 'components/Input';
import Button from 'components/Button';
import { useResetPasswordMutation } from 'api/rest.api';

import logoImg from 'assets/logo.png';

const ResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState('');

  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    const password = data.get('password');
    const confirmPassword = data.get('confirmPassword');
    if (password !== confirmPassword) {
      return setError('Password not matched');
    }

    await resetPassword({ resetToken: token, password })
      .unwrap()
      .then(() => {
        toast(
          'Your password has been updated successfully. Please signin with your new password.', {
            type: 'success',
          },
        );
        setTimeout(() => {
          navigate('/login');
        }, 2000);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="flex w-full h-full">
      <div className="flex flex-col m-auto items-center w-full max-w-md">
        <img
          src={logoImg}
          className="w-40 h-12"
        />
        <p className="mt-5 text-4xl font-semibold">Reset Your Password</p>
        <p className="mt-5 text-base text-gray-500 text-center">Please enter your new password</p>
        <form
          className="flex flex-col gap-y-2 mt-5 w-full"
          onSubmit={handleSubmit}
        >
          {error ? <span className="text-sm text-center text-red-500">{error}</span> : null}
          <Input
            name="password"
            type="password"
            placeholder="Password"
          />
          <Input
            name="confirmPassword"
            type="password"
            placeholder="Confirm Password"
          />
          <Button
            loading={isLoading}
            cssClass="mt-4"
            type="submit"
            variant="contained"
          >
            Submit
          </Button>
          <p
            className="text-main-green underline underline-offset-2 text-center cursor-pointer"
            onClick={() => navigate('/login')}
          >
            Sign In
          </p>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
