import moment from 'moment';

const workdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const utcToLocal = (hours, minutes) => {
  const timeOffset = new Date().getTimezoneOffset();
  const d = new Date();
  d.setHours(hours);
  d.setMinutes(minutes);
  d.setMinutes(d.getMinutes() - timeOffset);

  const date = moment(d).format('YYYY-MM-DD');
  const previousDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
  const nextDate = moment().add(1, 'days').format('YYYY-MM-DD');

  let result = [];
  if (date === previousDate) {
    result = [
      d.getHours(),
      d.getMinutes().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }),
      -1,
    ];
  } else if (date === nextDate) {
    result = [
      d.getHours(),
      d.getMinutes().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }),
      1,
    ];
  } else {
    result = [
      d.getHours(),
      d.getMinutes().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }),
      0,
    ];
  }
  return result;
};

const localToUtc = (hours, minutes) => {
  const timeOffset = new Date().getTimezoneOffset();
  const d = new Date();
  d.setHours(hours);
  d.setMinutes(minutes);
  d.setMinutes(d.getMinutes() + timeOffset);
  const date = moment(d).format('YYYY-MM-DD');
  const previousDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
  const nextDate = moment().add(1, 'days').format('YYYY-MM-DD');

  let result = [];
  if (date === previousDate) {
    result = [
      d.getHours(),
      d.getMinutes().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }),
      -1,
    ];
  } else if (date === nextDate) {
    result = [
      d.getHours(),
      d.getMinutes().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }),
      1,
    ];
  } else {
    result = [
      d.getHours(),
      d.getMinutes().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }),
      0,
    ];
  }
  return result;
};

const getPreviosDay = (day) => {
  let previosDay = '';
  switch (day) {
    case 'Monday':
      previosDay = 'Sunday';
      break;
    case 'Tuesday':
      previosDay = 'Monday';
      break;
    case 'Wednesday':
      previosDay = 'Tuesday';
      break;
    case 'Thursday':
      previosDay = 'Wednesday';
      break;
    case 'Friday':
      previosDay = 'Thursday';
      break;
    case 'Saturday':
      previosDay = 'Friday';
      break;
    default:
      previosDay = 'Saturday';
      break;
  }
  return previosDay;
};

const getNextDay = (day) => {
  let previosDay = '';
  switch (day) {
    case 'Monday':
      previosDay = 'Tuesday';
      break;
    case 'Tuesday':
      previosDay = 'Wednesday';
      break;
    case 'Wednesday':
      previosDay = 'Thursday';
      break;
    case 'Thursday':
      previosDay = 'Friday';
      break;
    case 'Friday':
      previosDay = 'Saturday';
      break;
    case 'Saturday':
      previosDay = 'Sunday';
      break;
    default:
      previosDay = 'Monday';
      break;
  }
  return previosDay;
};

const getLocalTimeSlots = (utcSlots) => {
  const slots = [];
  utcSlots.map((slot) => {
    const startUTC = slot.start.split(':');
    const endUTC = slot.end.split(':');
    const startLocal = utcToLocal(Number(startUTC[0]), Number(startUTC[1]));
    const endLocal = utcToLocal(Number(endUTC[0]), Number(endUTC[1]));

    slots.push({
      ...slot,
      // eslint-disable-next-line no-nested-ternary
      startDay:
        startLocal[2] === -1
          ? getPreviosDay(slot.startDay)
          : startLocal[2] === 1
          ? getNextDay(slot.startDay)
          : slot.startDay,
      start: `${`0${startLocal[0]}`.slice(-2)}:${startLocal[1]}`,
      // eslint-disable-next-line no-nested-ternary
      endDay:
        endLocal[2] === -1
          ? getPreviosDay(slot.endDay)
          : endLocal[2] === 1
          ? getNextDay(slot.endDay)
          : slot.endDay,
      end: `${endLocal[0]}:${endLocal[1]}`,
    });
  });

  return slots;
};

export { workdays, utcToLocal, localToUtc, getLocalTimeSlots, getPreviosDay, getNextDay };
