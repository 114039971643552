import React from 'react';

import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as ListIcon } from 'assets/icons/list.svg';
import { ReactComponent as HandHeartIcon } from 'assets/icons/hand_heart.svg';

const ThirdSection = () => {
  return (
    <div className="mx-auto p-5 w-full max-w-8xl">
      <div className="py-8 px-10 bg-light-green rounded-xl">
        <p className="text-gray-500">How it works</p>
        <p className="text-3xl font-medium">
          3 - CLICK appointment booking: SEARCH - SELECT - SCHEDULE
        </p>
        <div className="flex gap-x-2 mt-8">
          <div>
            <SearchIcon />
            <p className="mt-3 font-semibold">1. Search Providers or Clinics</p>
            <p className="mt-1 text-gray-500">
              Enter your location and search for Virtual Care appointments, Provider Location appointments OR Customer Location appointments.
            </p>
          </div>
          <div>
            <ListIcon />
            <p className="mt-3 font-semibold">2. Select Provider</p>
            <p className="mt-1 text-gray-500">
              Select the best Provider that meets your care needs based on your budget and schedule.
            </p>
          </div>
          <div>
            <HandHeartIcon />
            <p className="mt-3 font-semibold">3. Book Appointment</p>
            <p className="mt-1 text-gray-500">
              Once you have selected a specialist you can immediately make a booking. Access the care you need in minutes.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThirdSection;
