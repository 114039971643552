export const UserRole = {
  ADMIN: 'admin',
  CUSTOMER: 'customer',
  PROVIDER: 'provider',
  CLINIC_ADMIN: 'clinic-admin',
  CLINIC_MEMBER: 'clinic-member',
  MARKETING_MEMBER: 'marketing-member',
  REVENUE_ANALYST: 'revenue-analyst',
  HELPDESK: 'helpdesk',
};

export const UserStatus = {
  PENDING: 'pending',
  ACTIVE: 'active',
  BLOCKED: 'bloked',
  INACTIVE: 'inactive',
  ARCHIVED: 'archived',
};

export const AppointmentType = {
  VIRTUAL: 'video-visit',
  PROVIDER_LOCATION: 'provider-visit',
  CUSTOMER_LOCATION: 'customer-visit',
};

export const BookingStatus = {
  COMPLETED: 'completed',
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  INCOMPLETE: 'incomplete',
  CANCELLED_CUSTOMER: 'cancelled-customer',
  CANCELLED_PROVIDER: 'cancelled-provider',
  REJECTED_CUSTOMER: 'rejected-customer',
  REJECTED_PROVIDER: 'rejected-provider',
};
