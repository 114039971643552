import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import {
  BsCameraVideo,
  BsChatSquareText,
  BsChevronLeft,
  BsChevronRight,
  BsExclamationCircle,
  BsExclamationTriangle,
  BsFillCheckCircleFill,
  BsFillStarFill,
  BsQuestion,
} from 'react-icons/bs';
import { SlQuestion } from 'react-icons/sl';
import { TbBuildingHospital, TbClockQuestion, TbHome } from 'react-icons/tb';
import clsx from 'clsx';
import moment from 'moment';

import Avatar from 'components/Avatar';
import Button from 'components/Button';
import Footer from 'components/Footer';
import Map from 'components/Map';
import Select from 'components/Select';
import StarRating from 'components/StarRating';
import { useFetchProviderMutation, useFetchProviderTimeslotsMutation } from 'api/rest.api';
import { AppointmentType } from 'utils/labels';
import { getLocalTimeSlots, workdays } from 'utils/time';
import { useAppDispatch } from 'hooks/store';
import { setAppointmentInfo } from 'store/data.slice';

import { ReactComponent as UserCheckIcon } from 'assets/icons/user_check.svg';
import { ReactComponent as BlockItemIcon } from 'assets/icons/block_item.svg';

const ProviderDetails = ({ user }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [provider, setProvider] = useState(null);
  const [showAppointmentDetails, setShowAppointmentDetails] = useState(null);
  const [appointmentType, setAppointmentType] = useState(AppointmentType.VIRTUAL);
  const [tab, setTab] = useState('detail');
  const [timeslots, setTimeslots] = useState(null);
  const [slots, setSlots] = useState(null);
  const [selectedServiceItem, setSelectedServiceItem] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedSlot, setSelectedSlot] = useState(null);

  const [fetchProvider, { isLoading }] = useFetchProviderMutation();
  const [fetchProviderTimeslots, { isLoading: isSlotsFetching }] =
    useFetchProviderTimeslotsMutation();

  const getProvider = async (userId) => {
    await fetchProvider(userId)
      .unwrap()
      .then((res) => {
        if (res.success) {
          setProvider(res.user || null);
          if (res.user.services.length) {
            setSelectedServiceItem(res.user.services[0].items[0]);
            setSelectedService(res.user.services[0].items[0].resourceId);
            setAppointmentType(res.user.services[0].items[0].type);
          }
          if (res.user.locations.length) {
            setSelectedLocation({
              value: res.user.locations[0].id,
              label: res.user.locations[0].address,
            });
          }
          const temp = getLocalTimeSlots(res.user.slots).reduce((acc, item) => {
            const { startDay } = item;
            if (!acc[startDay]) {
              acc[startDay] = [];
            }
            acc[startDay].push(item);
            return acc;
          }, {});
          if (res.user.slots.length) {
            setTimeslots(temp);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleBook = () => {
    if (provider) {
      if (appointmentType === AppointmentType.PROVIDER_LOCATION && !selectedLocation) {
        return toast('Please select a location for appointment', { type: 'error' });
      }
      if (
        appointmentType === AppointmentType.CUSTOMER_LOCATION &&
        (!user || user.locations.length === 0)
      ) {
        return toast('Please add your location in settings page and try book appointment again', {
          type: 'error',
        });
      }
      if (!selectedService) {
        return toast('Please select a service', { type: 'error' });
      }
      if (!selectedServiceItem) {
        return toast('Please select a service item - duration', { type: 'error' });
      }
      if (!selectedSlot) {
        return toast('Please select a time slot for appointment', { type: 'error' });
      }
      dispatch(
        setAppointmentInfo({
          provider,
          location:
            appointmentType === AppointmentType.CUSTOMER_LOCATION
              ? user.locations[0]
              : selectedLocation,
          date: moment(selectedDate).format('YYYY-MM-DD'),
          slot: selectedSlot,
          type: appointmentType,
          service: provider.services.find((sv) => sv.id === selectedService),
          item: selectedServiceItem,
        }),
      );
      return navigate('/appointment/confirm');
    }
  };

  const handleChange = (type) => {
    if (type !== appointmentType) {
      setAppointmentType(type);
      setSelectedServiceItem(null);
    }
  };

  const getAppointmentType = (type) => {
    switch (type) {
      case AppointmentType.VIRTUAL:
        return 'Virtual visit';
      case AppointmentType.PROVIDER_LOCATION:
        return 'Clinic visit';
      case AppointmentType.CUSTOMER_LOCATION:
        return 'Mobile visit';
      default:
        return '';
    }
  };

  const defaultLocation = useMemo(() => {
    return provider && provider.locations.length > 0 ? provider.locations[0] : null;
  }, [provider]);

  const GoogleMap = useMemo(
    () => (
      <Map
        rounded
        center={{
          lat: 34.0584,
          lng: -118.278,
        }}
        markers={[
          {
            position: { lat: 34.0584, lng: -118.278 },
            title: 'Location one',
          },
          {
            position: { lat: 34.0784, lng: -118.298 },
            title: 'Location two',
          },
        ]}
      />
    ),
    [],
  );

  const availableServiceItems = useMemo(() => {
    if (provider) {
      const service = provider.services.find((service) => service.id === selectedService);
      if (service) {
        return service.items.filter((item) => item.type === appointmentType);
      }
    }
    return [];
  }, [provider, appointmentType, selectedService]);

  const availableTimeslots = useMemo(() => {
    if (slots) {
      const temp = slots.filter(({ slot, type }) => {
        if (type !== appointmentType) {
          return false;
        }
        if (!selectedServiceItem) {
          return true;
        }
        return !!slots.find((sl) => sl.slot === slot + selectedServiceItem.duration / 15 - 1);
      });
      const groupTemp = { morning: [], afternoon: [], evening: [], night: [] };
      temp.map((item) => {
        if (item.slot >= 24 && item.slot < 48) {
          groupTemp.morning.push(item);
        } else if (item.slot >= 48 && item.slot < 68) {
          groupTemp.afternoon.push(item);
        } else if (item.slot >= 68 && item.slot < 84) {
          groupTemp.evening.push(item);
        } else {
          groupTemp.night.push(item);
        }
      });
      // eslint-disable-next-line no-unused-vars
      const result = Object.fromEntries(Object.entries(groupTemp).filter(([_, v]) => v.length > 0));
      return Object.keys(result).length > 0 ? result : null;
    }
    return null;
  }, [slots, appointmentType]);

  useEffect(() => {
    if (timeslots) {
      const today = moment().format('dddd');
      let idx = workdays.indexOf(today);
      let gap = 0;
      const availableDays = Object.keys(timeslots);
      while (availableDays.indexOf(workdays[idx]) < 0) {
        idx = (idx + 1) % 7;
        gap++;
      }
      setSelectedDate(moment().add(gap, 'day').toDate());
    }
  }, [timeslots]);

  useEffect(() => {
    const getTimeSlots = async () => {
      await fetchProviderTimeslots({
        providerId: provider.id,
        locationId: selectedLocation.id,
        date: moment(moment(selectedDate).format('YYYY-MM-DD')).utc().format('YYYY-MM-DD HH:mm'),
      })
        .unwrap()
        .then((res) => {
          if (res.success) {
            setSlots(res.slots);
          }
        })
        .catch((err) => console.log(err));
    };

    if (selectedDate && provider && selectedLocation) {
      getTimeSlots();
    }
  }, [selectedDate, provider, selectedLocation]);

  useEffect(() => {
    if (id) {
      getProvider(id);
    }
  }, [id]);

  return (
    <div
      id="cp-provider-details-page"
      className="pt-[90px]"
    >
      {isLoading ? (
        <div className="p-10 flex items-center justify-center">
          <ClipLoader color="#08A284" />
        </div>
      ) : (
        <div className="mx-auto w-full max-w-8xl">
          <div className="flex items-center pt-5 pb-6 text-xs">
            <p className="text-gray-400">Search</p>
            <p className="mx-2">{'>'}</p>
            <p className="font-semibold">{'Booking'}</p>
          </div>
          {!provider ? (
            <div className="p-10 w-full text-lg text-center">Provider not available</div>
          ) : (
            <div className="flex gap-x-10 pb-10 w-full">
              <div className="w-4/6">
                {/* personal information */}
                <div className="bg-[#F8F8F8] rounded-xl">
                  <div className="flex items-center gap-x-2 p-4 text-xl font-semibold border-b border-[#E1E1E1]">
                    <UserCheckIcon />
                    Personal information
                  </div>
                  <div className="flex items-start justify-between gap-x-6 py-6 px-4">
                    <Avatar
                      user={provider}
                      size="md"
                    />
                    <div className="flex-1 flex items-start justify-between gap-x-6">
                      <div className="flex-1">
                        <p className="text-2xl font-semibold">
                          {`${provider.name} ${provider.lastName || ''}`}
                        </p>
                        <p className="text-sm">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut hendrerit arcu
                          vel bibendum dignissim. Morbi urna lectus, consectetur et fermentum et,
                          tincidunt vitae sapien. Aliquam dignissim ipsum tortor, ac posuere nibh
                          fringilla sit amet.
                        </p>
                      </div>
                      <div className="text-sm">
                        <div className="flex items-center gap-x-2">
                          <BsFillCheckCircleFill color="#08A284" />
                          Medical registration verified!
                        </div>
                        <div className="flex items-center gap-x-2">
                          <BsFillStarFill color="#08A284" />
                          99% (249 patience)
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* details view with tabs */}
                <div className="mt-8">
                  <div className="flex items-center">
                    <div
                      className={clsx('py-3 px-5 cursor-pointer', {
                        'text-[#6B6B6B]': tab !== 'detail',
                        'font-semibold bg-[#F8F8F8] rounded-t-xl': tab === 'detail',
                      })}
                      onClick={() => setTab('detail')}
                    >
                      Details
                    </div>
                    <div
                      className={clsx('py-3 px-5 cursor-pointer', {
                        'text-[#6B6B6B]': tab !== 'review',
                        'font-semibold bg-[#F8F8F8] rounded-t-xl': tab === 'review',
                      })}
                      onClick={() => setTab('review')}
                    >
                      Reviews (24)
                    </div>
                    <div
                      className={clsx('py-3 px-5 cursor-pointer', {
                        'text-[#6B6B6B]': tab !== 'consult',
                        'font-semibold bg-[#F8F8F8] rounded-t-xl': tab === 'consult',
                      })}
                      onClick={() => setTab('consult')}
                    >
                      Consult&Q/A
                    </div>
                    <div
                      className={clsx('py-3 px-5 cursor-pointer', {
                        'text-[#6B6B6B]': tab !== 'other',
                        'font-semibold bg-[#F8F8F8] rounded-t-xl': tab === 'other',
                      })}
                      onClick={() => setTab('other')}
                    >
                      Additional Info
                    </div>
                  </div>
                  <div
                    className={clsx('bg-[#F8F8F8] rounded-b-xl', {
                      'rounded-tr-xl': tab === 'detail',
                      'rounded-t-xl': tab !== 'detail',
                    })}
                  >
                    {tab === 'detail' ? (
                      <div className="p-5">
                        <div className="flex items-center justify-between text-sm">
                          <div>
                            <p className="text-sm text-main-green font-semibold">
                              {provider.clinicAdmin
                                ? provider.clinicAdmin.name
                                : `${provider.name} ${provider.lastName}`}
                            </p>
                            <p>{defaultLocation ? defaultLocation.address : 'No address'}</p>
                          </div>
                          <div className="flex items-center gap-x-1 cursor-pointer">
                            <BlockItemIcon
                              width={24}
                              height={24}
                            />
                            See provider pratise
                          </div>
                        </div>
                        <div className="py-4 border-b border-[#E1E1E1]">
                          <p className="font-semibold">Services</p>
                          <div>
                            <div className="flex items-center gap-x-1 py-2 px-3 text-sm font-semibold">
                              <div className="flex-1">Service</div>
                              <div className="flex-1">Duration</div>
                              <div className="flex-1">Price</div>
                              <div className="flex-1">Appointment</div>
                              <div className="w-10" />
                            </div>
                            {provider.services.map((service) =>
                              service.items.map((item) => (
                                <div
                                  key={`service-item-${item.id}`}
                                  className={clsx(
                                    'flex items-center gap-x-1 py-2 px-3 text-sm cursor-pointer',
                                    {
                                      'bg-white rounded-xl':
                                        selectedServiceItem && selectedServiceItem.id === item.id,
                                    },
                                  )}
                                  onClick={() => {
                                    setSelectedServiceItem(item);
                                    setSelectedService(item.resourceId);
                                    setAppointmentType(item.type);
                                  }}
                                >
                                  <div className="flex-1">{item.serviceName}</div>
                                  <div className="flex-1">{`${item.duration} mins`}</div>
                                  <div className="flex-1 text-main-green">{`$${item.price.toFixed(
                                    2,
                                  )}`}</div>
                                  <div className="flex-1">{getAppointmentType(item.type)}</div>
                                  <div className="flex w-10">
                                    <input
                                      name={item.id}
                                      type="radio"
                                      checked={
                                        selectedServiceItem && selectedServiceItem.id === item.id
                                      }
                                      className="w-4 h-4 text-main-green bg-gray-100 border-gray-300 accent-main-green"
                                    />
                                  </div>
                                </div>
                              )),
                            )}
                          </div>
                        </div>
                        <div className="flex items-start gap-x-2 pt-4">
                          <div className="flex-1 flex flex-col gap-y-4">
                            <p className="font-semibold">Time</p>
                            {timeslots ? (
                              <div>
                                {workdays.map((workday, index) => (
                                  <div
                                    key={workday}
                                    className={clsx({ 'mt-2': index !== 0 })}
                                  >
                                    {timeslots[workday] && timeslots[workday].length > 0 ? (
                                      <div>
                                        <p className="text-main-green text-lg font-semibold">
                                          {workday}
                                        </p>
                                        {timeslots[workday].map((ts) => (
                                          <div
                                            key={ts.id}
                                            className="font-medium"
                                          >
                                            {`${moment(ts.start, 'HH:mm').format(
                                              'hh:mm A',
                                            )} - ${moment(ts.end, 'HH:mm').format('hh:mm A')}`}
                                          </div>
                                        ))}
                                      </div>
                                    ) : null}
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <div className="text-center">No available hours</div>
                            )}
                          </div>
                          <div className="flex flex-col gap-y-2 w-3/5">
                            <p className="font-semibold">Location</p>
                            <Select
                              value={selectedLocation}
                              options={provider.locations.map((location) => ({
                                value: location.id,
                                label: location.address,
                              }))}
                              onChange={setSelectedLocation}
                            />
                            <div className="w-full h-64">{GoogleMap}</div>
                            <Button cssClass="text-xs">Get directions</Button>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {tab === 'review' ? (
                      <div>
                        <div className="flex items-center gap-x-2 py-4 px-5 text-xl font-semibold border-b border-[#E1E1E1]">
                          <BsChatSquareText />
                          <p>{`Patients' comments for ${provider.name} ${
                            provider.lastName || ''
                          }`}</p>
                        </div>
                        <div className="flex flex-col px-5 pb-4">
                          <div className="py-4 border-b border-[#E1E1E1]">
                            <div className="flex items-start justify-between">
                              <div className="flex items-center gap-x-4">
                                <Avatar
                                  hasIndicator={false}
                                  user={provider}
                                />
                                <div className="text-xs text-[#4A4A4A]">
                                  <div className="flex items-center gap-x-2">
                                    <span>Ashay Agraval</span>
                                    <StarRating
                                      value={4.5}
                                      size={16}
                                    />
                                  </div>
                                  <div>(Verified)</div>
                                </div>
                              </div>
                              <div className="text-sm text-[#6B6B6B]">2 months ago</div>
                            </div>
                            <div className="mt-2 font-semibold">
                              Visited for Dental Fillings, Dental Crowns
                            </div>
                            <div className="mt-2 text-sm">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras at
                              suscipit enim. Pellentesque id magna vitae sapien lacinia consectetur
                              sed consectetur diam. Integer magna velit, imperdiet vitae tellus ac,
                              malesuada gravida dolor. Ut elementum iaculis nisl sit amet fringilla.
                              Suspendisse efficitur eros vel lacus vehicula eleifend. Suspendisse
                              potenti. Mauris mauris nibh, consequat ultrices nisl eu, euismod
                              eleifend leo. Donec porttitor tristique rutrum. Aliquam et purus a
                              elit molestie aliquam ut sed ante. Curabitur dapibus ac nibh sit amet
                              vestibulum. Nam sit amet risus vitae sem pellentesque pharetra. Donec
                              vitae luctus leo, ac interdum ante. In ante neque, sollicitudin at
                              congue vitae, varius in nisl.
                            </div>
                          </div>
                          <div className="py-4">
                            <div className="flex items-start justify-between">
                              <div className="flex items-center gap-x-4">
                                <Avatar
                                  hasIndicator={false}
                                  user={provider}
                                />
                                <div className="text-xs text-[#4A4A4A]">
                                  <div className="flex items-center gap-x-2">
                                    <span>Ashay Agraval</span>
                                    <StarRating
                                      value={4}
                                      size={16}
                                    />
                                  </div>
                                  <div>(Verified)</div>
                                </div>
                              </div>
                              <div className="text-sm text-[#6B6B6B]">2 months ago</div>
                            </div>
                            <div className="mt-2 font-semibold">
                              Visited for Dental Fillings, Dental Crowns
                            </div>
                            <div className="mt-2 text-sm">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras at
                              suscipit enim. Pellentesque id magna vitae sapien lacinia consectetur
                              sed consectetur diam. Integer magna velit, imperdiet vitae tellus ac,
                              malesuada gravida dolor. Ut elementum iaculis nisl sit amet fringilla.
                              Suspendisse efficitur eros vel lacus vehicula eleifend. Suspendisse
                              potenti. Mauris mauris nibh, consequat ultrices nisl eu, euismod
                              eleifend leo. Donec porttitor tristique rutrum. Aliquam et purus a
                              elit molestie aliquam ut sed ante. Curabitur dapibus ac nibh sit amet
                              vestibulum. Nam sit amet risus vitae sem pellentesque pharetra. Donec
                              vitae luctus leo, ac interdum ante. In ante neque, sollicitudin at
                              congue vitae, varius in nisl.
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {tab === 'consult' ? (
                      <div>
                        <div className="flex items-center gap-x-2 py-4 px-5 text-xl font-semibold border-b border-[#E1E1E1]">
                          <TbClockQuestion />
                          <p>Common questions & answers</p>
                        </div>
                        <div className="flex flex-col px-5 pb-4">
                          <div className="py-4 border-b border-[#E1E1E1]">
                            <div className="flex items-start gap-x-4">
                              <div className="p-2 bg-white rounded-full">
                                <BsQuestion />
                              </div>
                              <div>
                                <p className="font-semibold">
                                  Where does Dr. Rachel Dermott practice?
                                </p>
                                <p className="mt-1 text-sm leading-4">
                                  Dr. Rachel Dermott practices at Perfecto Dental and Implant Clinic
                                  - Panathur. The doctor also provides online video consultation
                                  through CarePlatform.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="py-4 border-b border-[#E1E1E1]">
                            <div className="flex items-start gap-x-4">
                              <div className="p-2 bg-white rounded-full">
                                <BsQuestion />
                              </div>
                              <div>
                                <p className="font-semibold">
                                  {`How can I take Dr. Rachel Dermott's appointment ?`}
                                </p>
                                <p className="mt-1 text-sm leading-4">
                                  You can take Dr. Rachael Dermott appointment online through Practo
                                  for in-clinic visit or for video consultation with the doctor.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="py-4">
                            <div className="flex items-start gap-x-4">
                              <div className="p-2 bg-white rounded-full">
                                <BsQuestion />
                              </div>
                              <div>
                                <p className="font-semibold">
                                  Why do patients visit Dr. Rachel Dermott?
                                </p>
                                <p className="mt-1 text-sm leading-4">
                                  Patients frequently visit Dr. Rachel Dermott for Oral Surgery
                                  Procedures, Crowns and Bridges Fixing, Tooth Extraction. To see
                                  more reasons visit the doctor profile on Practo.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {tab === 'other' ? (
                      <div>
                        <div className="flex items-center gap-x-2 py-4 px-5 text-xl font-semibold border-b border-[#E1E1E1]">
                          <BsExclamationTriangle />
                          <p>Additional information</p>
                        </div>
                        <div className="flex flex-col px-5 pb-4">
                          <div className="py-4 border-b border-[#E1E1E1]">
                            <p className="mb-2 font-semibold">Services</p>
                            <div className="grid grid-cols-3">
                              <div className="text-sm">• Oral Surgery Procedures</div>
                              <div className="text-sm">• Crowns and Bridges Fixing</div>
                              <div className="text-sm">• Tooth Extraction</div>
                              <div className="text-sm">• Surgical Tooth Extraction</div>
                            </div>
                          </div>
                          <div className="grid grid-cols-2 gap-4 py-4 border-b border-[#E1E1E1]">
                            <div>
                              <p className="mb-2 font-semibold">Specialization</p>
                              <div className="flex flex-col">
                                <div className="text-sm">• Oral And MaxilloFacial Surgeon</div>
                                <div className="text-sm">• Dental Surgeon</div>
                                <div className="text-sm">• Dentist</div>
                              </div>
                            </div>
                            <div>
                              <p className="mb-2 font-semibold">Awards and Recognitions</p>
                              <div className="flex flex-col">
                                <div className="text-sm">
                                  • Best paper presentation in AOMSI - 2015
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="grid grid-cols-2 gap-4 py-4 border-b border-[#E1E1E1]">
                            <div>
                              <p className="mb-2 font-semibold">Education</p>
                              <div className="flex flex-col">
                                <div className="text-sm">
                                  • BDS - Dr. NTR University of Health Sciences Andhra Pradesh, 2009
                                </div>
                                <div className="text-sm">
                                  • MDS - Oral & Maxillofacial Surgery - Rajiv Gandhi University of
                                  Health Sciences, Bangalore, India, 2017
                                </div>
                              </div>
                            </div>
                            <div>
                              <p className="mb-2 font-semibold">Memberships</p>
                              <div className="flex flex-col">
                                <div className="text-sm">• Dental Council of India</div>
                              </div>
                            </div>
                          </div>
                          <div className="grid grid-cols-2 gap-4 py-4">
                            <div>
                              <p className="mb-2 font-semibold">Experience</p>
                              <div className="flex flex-col">
                                <div className="text-sm">
                                  • 2009 - 2010 Junior resident at Government dental college
                                  hospital
                                </div>
                                <div className="text-sm">
                                  • 2011 - 2014 Duty doctor at RIMS HOSPITAL
                                </div>
                              </div>
                            </div>
                            <div>
                              <p className="mb-2 font-semibold">Registrations</p>
                              <div className="flex flex-col">
                                <div className="text-sm">
                                  • A 8277 Andhra Pradesh State Dental Council, 2010
                                </div>
                              </div>
                            </div>
                          </div>
                          <Button
                            color="danger"
                            cssClass="text-sm font-medium"
                          >
                            Report an error
                          </Button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="flex-1 flex flex-col gap-y-6">
                <div>
                  <div className="flex items-center gap-x-2 text-xl font-semibold">
                    <p>Choose type of appointment</p>
                    <SlQuestion color="#08A284" />
                  </div>
                  <div className="mt-3 px-6 border border-[#E1E1E1] rounded-xl">
                    <div
                      className="flex items-center justify-between py-6 cursor-pointer"
                      onClick={() => handleChange(AppointmentType.VIRTUAL)}
                    >
                      <label
                        htmlFor="virtual-visit"
                        className="flex items-center gap-x-2"
                      >
                        Virtual visit
                        <Popover
                          isOpen={showAppointmentDetails === AppointmentType.VIRTUAL}
                          positions={['bottom', 'top', 'left', 'right']}
                          content={({ position, childRect, popoverRect }) => (
                            <ArrowContainer
                              position={position}
                              childRect={childRect}
                              popoverRect={popoverRect}
                              arrowStyle={{ display: 'none' }}
                            >
                              <div className="w-52 p-3 text-sm bg-white rounded-lg shadow-2xl">
                                Means Vide/Audio/Text. Online visit
                              </div>
                            </ArrowContainer>
                          )}
                        >
                          <div
                            onMouseEnter={() => setShowAppointmentDetails(AppointmentType.VIRTUAL)}
                            onMouseLeave={() => setShowAppointmentDetails(null)}
                          >
                            <BsExclamationCircle color="#08A284" />
                          </div>
                        </Popover>
                      </label>
                      <input
                        id="virtual-visit"
                        type="radio"
                        checked={appointmentType === AppointmentType.VIRTUAL}
                        className="w-4 h-4 text-main-green bg-gray-100 border-gray-300 accent-main-green"
                      />
                    </div>
                    <div
                      className="flex items-center justify-between py-6 border-y border-[#E1E1E1] cursor-pointer"
                      onClick={() => handleChange(AppointmentType.PROVIDER_LOCATION)}
                    >
                      <label
                        htmlFor="virtual-visit"
                        className="flex items-center gap-x-2"
                      >
                        Clinic visit
                        <Popover
                          isOpen={showAppointmentDetails === AppointmentType.PROVIDER_LOCATION}
                          positions={['bottom', 'top', 'left', 'right']}
                          content={({ position, childRect, popoverRect }) => (
                            <ArrowContainer
                              position={position}
                              childRect={childRect}
                              popoverRect={popoverRect}
                              arrowStyle={{ display: 'none' }}
                            >
                              <div className="w-52 p-3 text-sm bg-white rounded-lg shadow-2xl">
                                Means customer arrives at provider’s location
                              </div>
                            </ArrowContainer>
                          )}
                        >
                          <div
                            onMouseEnter={() =>
                              setShowAppointmentDetails(AppointmentType.PROVIDER_LOCATION)
                            }
                            onMouseLeave={() => setShowAppointmentDetails(null)}
                          >
                            <BsExclamationCircle color="#08A284" />
                          </div>
                        </Popover>
                      </label>
                      <input
                        id="virtual-visit"
                        type="radio"
                        checked={appointmentType === AppointmentType.PROVIDER_LOCATION}
                        className="w-4 h-4 text-main-green bg-gray-100 border-gray-300 accent-main-green"
                      />
                    </div>
                    <div
                      className="flex items-center justify-between py-6 cursor-pointer"
                      onClick={() => handleChange(AppointmentType.CUSTOMER_LOCATION)}
                    >
                      <label
                        htmlFor="virtual-visit"
                        className="flex items-center gap-x-2"
                      >
                        Mobile visit
                        <Popover
                          isOpen={showAppointmentDetails === AppointmentType.CUSTOMER_LOCATION}
                          positions={['bottom', 'top', 'left', 'right']}
                          content={({ position, childRect, popoverRect }) => (
                            <ArrowContainer
                              position={position}
                              childRect={childRect}
                              popoverRect={popoverRect}
                              arrowStyle={{ display: 'none' }}
                            >
                              <div className="w-52 p-3 text-sm bg-white rounded-lg shadow-2xl">
                                Means provider arrives at customer’s location
                              </div>
                            </ArrowContainer>
                          )}
                        >
                          <div
                            onMouseEnter={() =>
                              setShowAppointmentDetails(AppointmentType.CUSTOMER_LOCATION)
                            }
                            onMouseLeave={() => setShowAppointmentDetails(null)}
                          >
                            <BsExclamationCircle color="#08A284" />
                          </div>
                        </Popover>
                      </label>
                      <input
                        id="virtual-visit"
                        type="radio"
                        checked={appointmentType === AppointmentType.CUSTOMER_LOCATION}
                        className="w-4 h-4 text-main-green bg-gray-100 border-gray-300 accent-main-green"
                      />
                    </div>
                  </div>
                </div>
                <div className="border border-[#E1E1E1] rounded-xl">
                  <div className="flex items-center gap-x-2 py-3 px-5 text-xl font-semibold bg-[#FBFBFB] rounded-t-xl border-b border-[#E1E1E1]">
                    {appointmentType === AppointmentType.VIRTUAL ? (
                      <div className="p-2 bg-light-green rounded-full">
                        <BsCameraVideo color="#08A284" />
                      </div>
                    ) : null}
                    {appointmentType === AppointmentType.PROVIDER_LOCATION ? (
                      <div className="p-2 bg-light-green rounded-full">
                        <TbBuildingHospital color="#08A284" />
                      </div>
                    ) : null}
                    {appointmentType === AppointmentType.CUSTOMER_LOCATION ? (
                      <div className="p-2 bg-light-green rounded-full">
                        <TbHome color="#08A284" />
                      </div>
                    ) : null}
                    {getAppointmentType(appointmentType)}
                  </div>
                  <div className="flex flex-col gap-y-2 p-2">
                    <Select
                      value={
                        provider.services.find((service) => service.id === selectedService)
                          ? {
                              value: provider.services.find(
                                (service) => service.id === selectedService,
                              ).id,
                              label: provider.services.find(
                                (service) => service.id === selectedService,
                              ).name,
                            }
                          : null
                      }
                      options={provider.services.map((service) => ({
                        value: service.id,
                        label: service.name,
                      }))}
                      placeholder="Select service..."
                      onChange={(service) => setSelectedService(service.value)}
                    />
                    {availableServiceItems.length ? (
                      <Select
                        value={
                          selectedServiceItem
                            ? {
                                value: selectedServiceItem.id,
                                label: `${
                                  selectedServiceItem.duration
                                } mins - $${selectedServiceItem.price.toFixed(2)}`,
                              }
                            : null
                        }
                        options={availableServiceItems.map((as) => ({
                          value: as.id,
                          label: `${as.duration} mins - $${as.price.toFixed(2)}`,
                        }))}
                        placeholder="Select service item..."
                        onChange={(as) =>
                          setSelectedServiceItem(
                            availableServiceItems.find((item) => item.id === as.value),
                          )
                        }
                      />
                    ) : (
                      <div>No service items</div>
                    )}
                    <div className="flex flex-col py-6 px-3 bg-[#FBFBFB] rounded-xl">
                      <DatePicker
                        selected={selectedDate}
                        renderCustomHeader={({ monthDate, decreaseMonth, increaseMonth }) => (
                          <div className="flex items-center pl-4 my-2">
                            <span
                              className="react-datepicker__current-month text-lg font-semibold"
                              style={{ fontFamily: 'Poppins' }}
                            >
                              {monthDate.toLocaleString('en-US', {
                                month: 'short',
                                year: 'numeric',
                              })}
                            </span>
                            <div className="flex items-center gap-x-1 ml-2">
                              <button
                                aria-label="Previous Month"
                                className="relative p-1 rounded-full hover:bg-gray-100"
                                onClick={decreaseMonth}
                              >
                                <BsChevronLeft
                                  color="#08A284"
                                  className="text-base font-bold"
                                />
                              </button>
                              <button
                                aria-label="Next Month"
                                className="relative p-1 rounded-full hover:bg-gray-100"
                                onClick={increaseMonth}
                              >
                                <BsChevronRight
                                  color="#08A284"
                                  className="text-base font-bold"
                                />
                              </button>
                            </div>
                          </div>
                        )}
                        dayClassName={(date) =>
                          moment(date).isSame(moment(), 'day')
                            ? 'p-2 text-xl text-main-green bg-light-green rounded-full hover:bg-main-green hover:text-white hover:rounded-full'
                            : 'p-2 text-xl font-medium hover:rounded-full'
                        }
                        inline
                        onChange={(date) => setSelectedDate(date)}
                      />
                      <div>
                        {isSlotsFetching ? (
                          <div>
                            <ClipLoader />
                          </div>
                        ) : (
                          <div className="flex mt-3 font-medium">
                            {availableTimeslots ? (
                              <div className="w-full">
                                {Object.keys(availableTimeslots).map((time, index) => (
                                  <div
                                    key={`time-${time}`}
                                    className={clsx({ 'mt-3': index !== 0 })}
                                  >
                                    <div className="flex items-end gap-x-2 mb-2">
                                      <p className="capitalize font-semibold">{time}</p>
                                      <p>{`(${availableTimeslots[time].length} slots)`}</p>
                                    </div>
                                    <div className="grid grid-cols-4 gap-2 w-full">
                                      {availableTimeslots[time].map((item, index) => (
                                        <div
                                          key={`time-slot-${index}`}
                                          className={clsx(
                                            'py-1 px-2 text-center text-sm rounded-lg cursor-pointer',
                                            {
                                              'bg-main-green text-white':
                                                selectedSlot && item.slot === selectedSlot.slot,
                                              'bg-medium-green':
                                                !selectedSlot || item.slot !== selectedSlot.slot,
                                            },
                                          )}
                                          onClick={() => setSelectedSlot(item)}
                                        >
                                          {moment({
                                            hour: item.slot / 4,
                                            minute: 15 * (item.slot % 4),
                                          }).format('h:mm A')}
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <div>No available slots</div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <Button
                  fullWidth
                  onClick={handleBook}
                >
                  Book appointment
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
      <Footer />
    </div>
  );
};

export default ProviderDetails;
