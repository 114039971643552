import React, { useEffect, useState } from 'react';

import Tabs from 'components/Tabs';
import Input from 'components/Input';
import { useFetchProvidersMutation } from 'api/rest.api';
import InfiniteScrollingContainer from 'components/InfiniteScrollingContainer';
import UserItem from 'components/UserItem';

const tabs = [
  { id: 1, value: 'provider', label: 'Individual' },
  { id: 2, value: 'clinic-admin', label: 'Clinic' },
];

const Providers = () => {
  const [tab, setTab] = useState('provider');
  const [hasMore, setHasMore] = useState(true);
  const [providers, setProviders] = useState([]);

  const [fetchProviders] = useFetchProvidersMutation();

  const getProviders = async () => {
    const data = {
      skip: providers.length,
    };
    await fetchProviders(data)
      .unwrap()
      .then((res) => {
        if (res.success) {
          setProviders([...providers, ...res.providers]);
          if (res.providers.length === 0 || res.providers.length < 30) {
            setHasMore(false);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getProviders();
  }, []);

  return (
    <div
      id="cp-providers"
      className="relative w-full h-full flex flex-col pt-10 pb-4 px-4 overflow-hidden"
    >
      <div className="flex items-center justify-between border-b border-gray-200">
        <Tabs
          currentTab={tab}
          options={tabs}
          onChange={setTab}
        />
        <div>
          <Input />
        </div>
      </div>
      <div
        id="scrollableDiv"
        className="mt-2 overflow-y-auto"
      >
        <InfiniteScrollingContainer
          dataLength={providers.length}
          onNext={getProviders}
          hasMore={hasMore}
          endMessage={providers.length === 0 ? 'No providers available' : 'No providers anymore'}
          scrollableTarget="scrollableDiv"
        >
          <div className="grid grid-cols-5 gap-4 overflow-auto">
            {providers.map((provider) => (
              <UserItem
                key={`provider-${provider.id}`}
                user={provider}
              />
            ))}
          </div>
        </InfiniteScrollingContainer>
      </div>
    </div>
  );
};

export default Providers;
