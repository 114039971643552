import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import { Wrapper } from '@googlemaps/react-wrapper';

import Routes from 'routes';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { selectAuthToken, selectCurrentUser } from 'store/auth.slice';
import { setFavoriteProviders, setLanguages, setProviderTypes } from 'store/data.slice';
import { selectSyncMap, syncConnect, updateStatus } from 'store/status.slice';
import {
  useFetchFavoriteProvidersMutation,
  useFetchLanguagesMutation,
  useFetchProviderTypesMutation,
  useGetSyncTokenMutation,
} from 'api/rest.api';

function App() {
  const dispatch = useAppDispatch();

  const [getSyncToken] = useGetSyncTokenMutation();
  const [fetchProviderTypes] = useFetchProviderTypesMutation();
  const [fetchLanguages] = useFetchLanguagesMutation();
  const [fetchFavoriteProviders] = useFetchFavoriteProvidersMutation();

  const token = useAppSelector(selectAuthToken) || '';
  const user = useAppSelector(selectCurrentUser) || null;
  const syncMap = useAppSelector(selectSyncMap) || null;

  const onIdle = () => {
    if (user && syncMap) {
      dispatch(updateStatus({ user, status: 'away', syncMap }));
    }
  };

  const onActive = () => {
    if (user && syncMap) {
      dispatch(updateStatus({ user, status: 'online', syncMap }));
    }
  };

  useIdleTimer({
    // eslint-disable-next-line no-undef
    timeout: process.env.REACT_APP_IDLE_TIMEOUT,
    onIdle,
    onActive,
  });

  const onSync = async (user) => {
    await getSyncToken()
      .unwrap()
      .then((res) => {
        dispatch(syncConnect({ user, token: res.token }));
      })
      .catch((err) => console.log(err));
  };

  const fetchBasicData = () => {
    fetchProviderTypes()
      .unwrap()
      .then((res) => {
        if (res.success) {
          dispatch(setProviderTypes({ providerTypes: res.providerTypes }));
        }
      })
      .catch((err) => console.log(err));

    fetchLanguages()
      .unwrap()
      .then((res) => {
        if (res.success) {
          dispatch(setLanguages({ languages: res.languages }));
        }
      })
      .catch((err) => console.log(err));
  };

  const fetchData = (user) => {
    fetchProviderTypes()
      .unwrap()
      .then((res) => {
        if (res.success) {
          dispatch(setProviderTypes({ providerTypes: res.providerTypes }));
        }
      })
      .catch((err) => console.log(err));
    fetchFavoriteProviders(user.id)
      .unwrap()
      .then((res) => {
        if (res.success) {
          dispatch(setFavoriteProviders({ providers: res.providers }));
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (token && user) {
      onSync(user);
      fetchData(user);
    }
  }, [user, token]);

  useEffect(() => {
    fetchBasicData();
  }, []);

  return (
    <BrowserRouter>
      <Wrapper
        // eslint-disable-next-line no-undef
        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        version="beta"
        libraries={['places', 'marker']}
      >
        <Routes />
      </Wrapper>
    </BrowserRouter>
  );
}

export default App;
