import React from 'react';
import Rating from 'react-rating';

import { ReactComponent as FullStarIcon } from 'assets/yellow_star.svg';
import { ReactComponent as EmptyStarIcon } from 'assets/gray_star.svg';

const StarRating = ({ readonly = true, value, size = 20 }) => {
  return (
    <Rating
      className="star-rating"
      readonly={readonly}
      initialRating={value}
      emptySymbol={
        <EmptyStarIcon
          width={size}
          height={size}
        />
      }
      fullSymbol={
        <FullStarIcon
          width={size}
          height={size}
        />
      }
    />
  );
};

export default StarRating;
