import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  redirectPath: null,
  languages: [],
  providerTypes: [],
  favorites: [],
  appointmentInfo: {},
};

export const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setProviderTypes: (state, { payload: { providerTypes } }) => {
      state.providerTypes = providerTypes;
    },
    setLanguages: (state, { payload: { languages } }) => {
      state.languages = languages;
    },
    setFavoriteProviders: (state, { payload: { providers } }) => {
      state.favorites = providers;
    },
    setAppointmentInfo: (state, { payload }) => {
      state.appointmentInfo = payload;
    },
    setRedirectPath: (state, { payload }) => {
      state.redirectPath = payload;
    },
  },
});

export const {
  setAppointmentInfo,
  setProviderTypes,
  setFavoriteProviders,
  setRedirectPath,
  setLanguages,
} = dataSlice.actions;

export default dataSlice.reducer;

export const selectProviderTypes = (state) => state.data.providerTypes;
export const selectLanguages = (state) => state.data.languages;
export const selectFavoriteProviers = (state) => state.data.favorites;
export const selectAppointmentInfo = (state) => state.data.appointmentInfo;
export const selectRedirectPath = (state) => state.data.redirectPath;
