import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import { GiHamburgerMenu } from 'react-icons/gi';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { MdOutlineNotificationsNone } from 'react-icons/md';

import Avatar from './Avatar';
import StatusIndicator from './StatusIndicator';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { setUserLoggedOut } from 'store/auth.slice';
import { selectSyncMap, syncDisconnect, updateStatus } from 'store/status.slice';
import menuItems from 'utils/menu.json';

import logoImg from 'assets/logo.png';

const Header = ({ user }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const syncMap = useAppSelector(selectSyncMap) || null;
  const [showMenu, setShowMenu] = useState(false);
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);

  const onUpdateStatus = (status) => {
    dispatch(updateStatus({ user, status, syncMap }));
  };

  // eslint-disable-next-line no-unused-vars
  const onSignout = () => {
    dispatch(setUserLoggedOut());
    dispatch(syncDisconnect({ user, syncMap }));
  };

  return (
    <div className="fixed top-0 flex items-center justify-between py-5 px-10 w-full bg-white border-b z-10">
      <div className="flex items-center gap-x-20">
        <img
          className="w-36 h-11 cursor-pointer"
          src={logoImg}
          alt="header-logo"
          onClick={() => {
            if (user) {
              navigate('/dashboard');
            } else {
              navigate('/');
            }
          }}
        />
        {user ? (
          <div></div>
        ) : (
          <div className="flex gap-x-8">
            <div className="font-semibold cursor-pointer">Customer</div>
            <div className="font-semibold cursor-pointer">Provider</div>
            <div className="font-semibold cursor-pointer">Clinic</div>
            <div className="font-semibold cursor-pointer">Corporate</div>
            <div className="font-semibold cursor-pointer">Help Desk</div>
            <div className="font-semibold cursor-pointer">About</div>
          </div>
        )}
      </div>
      {user ? (
        <div className="flex items-center gap-x-3 mt-auto">
          <Popover
            isOpen={showNotifications}
            positions={['bottom', 'top', 'left', 'right']}
            containerClassName="react-tiny-popover-container z-50"
            content={({ position, childRect, popoverRect }) => (
              <ArrowContainer
                position={position}
                childRect={childRect}
                popoverRect={popoverRect}
              >
                <div className="mr-2 py-3 px-5 bg-white rounded-lg shadow-2xl">
                  all notifications view simply
                </div>
              </ArrowContainer>
            )}
            className="test"
            onClickOutside={() => setShowNotifications(false)}
          >
            <div
              className="relative p-1 cursor-pointer"
              onClick={() => setShowNotifications(!showNotifications)}
            >
              <MdOutlineNotificationsNone className="text-2xl" />
              <span className="absolute top-0 right-0 flex items-center justify-center w-[16px] h-[16px] text-[8px] text-white bg-red-600 rounded-full">10</span>
            </div>
          </Popover>
          <Popover
            isOpen={showMenu}
            positions={['bottom', 'top', 'left', 'right']}
            containerClassName="react-tiny-popover-container z-50"
            content={({ position, childRect, popoverRect }) => (
              <ArrowContainer
                position={position}
                childRect={childRect}
                popoverRect={popoverRect}
              >
                <div className="mt-2 mr-2 py-3 bg-white rounded-lg shadow-2xl">
                  <div className="relative flex items-center gap-x-2 px-5 pb-3 cursor-pointer">
                    <Avatar
                      user={user}
                      size="md"
                    />
                    <div className="flex flex-col">
                      <div className="">{`${user.name} ${user.lastName}`}</div>
                      <div className="text-sm text-gray-500 capitalize">
                        {user.role.replace('-', ' ')}
                      </div>
                      <Popover
                        isOpen={showStatusDropdown}
                        positions={['bottom', 'top', 'left', 'right']}
                        containerClassName="react-tiny-popover-container z-50"
                        content={({ position, childRect, popoverRect }) => (
                          <ArrowContainer
                            position={position}
                            childRect={childRect}
                            popoverRect={popoverRect}
                          >
                            <div className="mr-2 py-3 px-5 bg-white rounded-lg shadow-2xl">
                              <div
                                className="flex items-center gap-x-2 py-1 px-4 text-sm hover:bg-gray-100 cursor-pointer"
                                onClick={() => onUpdateStatus('online')}
                              >
                                <div className="w-3 h-3 bg-green-600 rounded-full" />
                                Available
                              </div>
                              <div
                                className="flex items-center gap-x-2 py-1 px-4 text-sm hover:bg-gray-100 cursor-pointer"
                                onClick={() => onUpdateStatus('away')}
                              >
                                <div className="w-3 h-3 bg-amber-300 rounded-full" />
                                Away
                              </div>
                              <div
                                className="flex items-center gap-x-2 py-1 px-4 text-sm hover:bg-gray-100 cursor-pointer"
                                onClick={() => onUpdateStatus('busy')}
                              >
                                <div className="w-3 h-3 bg-red-500 rounded-full" />
                                Busy
                              </div>
                            </div>
                          </ArrowContainer>
                        )}
                        className="test"
                        onClickOutside={() => setShowStatusDropdown(false)}
                      >
                        <div
                          className="flex items-center gap-x-2 text-sm"
                          onClick={() => setShowStatusDropdown(!showStatusDropdown)}
                        >
                          <StatusIndicator
                            labelView
                            user={user}
                          />
                          <div className="text-xs">
                            {showStatusDropdown ? <BsChevronUp /> : <BsChevronDown />}
                          </div>
                        </div>
                      </Popover>
                    </div>
                  </div>
                  <div className="py-3 border-y border-[#E1E1E1]">
                    {menuItems.map((menuItem, index) => (
                      <div
                        key={`menu-item-${index}`}
                        className="py-1 px-5 hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                          navigate(menuItem.path);
                          setShowMenu(false);
                        }}
                      >
                        {menuItem.title}
                      </div>
                    ))}
                  </div>
                  <div
                    className="pt-3 px-5 cursor-pointer"
                    onClick={onSignout}
                  >
                    Log Out
                  </div>
                </div>
              </ArrowContainer>
            )}
            className="test"
            onClickOutside={() => setShowMenu(false)}
          >
            <div
              className="cursor-pointer"
              onClick={() => setShowMenu(true)}
            >
              <Avatar user={user} />
            </div>
          </Popover>
        </div>
      ) : (
        <div className="flex items-center gap-x-4">
          <div
            className="py-1 px-3 border border-[#E1E1E1] rounded-full shadow-xl cursor-pointer"
            onClick={() => navigate('/login')}
          >
            Sign In
          </div>
          <div className="flex items-center gap-x-2 py-1 px-3 border border-medium-green rounded-full cursor-pointer">
            <span className="font-semibold">Menu</span>
            <GiHamburgerMenu />
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
