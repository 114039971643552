import React from 'react';

import CustomerDashboard from './CustomerDashboard';
import { UserRole } from 'utils/labels';

const Dashboard = ({ user }) => {
  return (
    <div
      id="cp-dashboard"
      className="w-full h-full flex flex-col pt-10 pb-4 px-4 overflow-hidden"
    >
      <p className="ml-2 text-4xl font-semibold">{`Hi! ${user.name} ${user.lastName || ''}👋`}</p>
      {user.role === UserRole.CUSTOMER ? <CustomerDashboard /> : null}
    </div>
  );
};

export default Dashboard;
