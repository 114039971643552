import React from 'react';
import { Routes as Switch, Route, Navigate, Outlet, useLocation } from 'react-router-dom';

import Layout from 'components/Layout';
import { ForgotPassword, Login, Register, ResetPassword, Verify } from 'pages/Auth';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { selectAuthToken, selectCurrentUser } from 'store/auth.slice';
import { setRedirectPath } from 'store/data.slice';
import Home from 'pages/Home';
import Dashboard from 'pages/Dashboard';
import Favorites from 'pages/Favorites';
import Providers from 'pages/Providers';
import ProviderDetails from 'pages/ProviderDetails';
import AppointmentConfirmation from 'pages/AppointmentConfirmation';
import Settings from 'pages/Settings';

const RequireAuth = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectAuthToken) || '';
  if (!token) {
    dispatch(setRedirectPath(location.pathname));
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};

const Routes = () => {
  const user = useAppSelector(selectCurrentUser) || null;

  return (
    <Layout>
      <Switch>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/verify" element={<Verify />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/providers/:id" element={<ProviderDetails user={user} />} />
        <Route element={<RequireAuth />}>
          <Route path="/dashboard" element={<Dashboard user={user} />} />
          <Route path="/favorites" element={<Favorites user={user} />} />
          <Route path="/providers" element={<Providers user={user} />} />
          <Route path="/appointment/confirm" element={<AppointmentConfirmation user={user} />} />
          <Route path="/settings" element={<Settings user={user} />} />
        </Route>
      </Switch>
    </Layout>
  );
};

export default Routes;
