import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import Slider from 'react-slick';
import ReactPaginate from 'react-paginate';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { City } from 'country-state-city';
import { ClipLoader } from 'react-spinners';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import { AiOutlineClose } from 'react-icons/ai';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import moment from 'moment';
import clsx from 'clsx';

import SecondSection from './SecondSection';
import ThirdSection from './ThirdSection';
import FourthSection from './FourthSection';
import FifthSection from './FifthSection';
import Footer from 'components/Footer';
import Toggle from 'components/Toggle';
import Input from 'components/Input';
import Button from 'components/Button';
import Map from 'components/Map';
import UserItem from 'components/UserItem';
import { useAppSelector } from 'hooks/store';
import { selectProviderTypes } from 'store/data.slice';
import { useFetchProvidersMutation, useGetGeoInfoMutation } from 'api/rest.api';
import { calcCrow, getCDNImage } from 'utils/common';

import logoImg from 'assets/logo.png';
import { ReactComponent as EarthIcon } from 'assets/icons/earth.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/menu.svg';
import { ReactComponent as BlockIcon } from 'assets/icons/block.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg';
import { ReactComponent as SubItemIcon } from 'assets/icons/sub_item.svg';
import { ReactComponent as MarkerIcon } from 'assets/icons/marker.svg';
import { ReactComponent as ClinicIcon } from 'assets/icons/clinic_bag.svg';

const SliderPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} items-center justify-center h-full z-10`}
      style={{ ...style, display: 'flex', left: '-12px' }}
    >
      <div
        className="relative p-1 text-sm text-main-dark bg-white border border-solid border-main-gray rounded-full z-20 hover:text-main-dark hover:bg-gray-100"
        onClick={onClick}
      >
        <BsChevronLeft />
      </div>
      <div
        className="absolute left-0 w-14 h-full z-10"
        style={{
          backgroundImage: 'linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0))',
        }}
      />
    </div>
  );
};

const SliderNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} items-center justify-center h-full`}
      style={{ ...style, display: 'flex', right: '-12px' }}
    >
      <div className="relative flex items-center h-full">
        <div
          className="absolute right-0 w-14 h-full z-10"
          style={{
            backgroundImage: 'linear-gradient(to left, rgba(255,255,255,1), rgba(255,255,255,0))',
          }}
        />
        <div
          className="relative p-1 text-sm text-main-dark bg-white border border-solid border-main-gray rounded-full shadow-2xl z-20 hover:text-main-dark hover:bg-gray-100"
          onClick={onClick}
        >
          <BsChevronRight />
        </div>
      </div>
    </div>
  );
};

const sliderSettings = {
  infinite: false,
  speed: 500,
  slidesToShow: 10,
  slidesToScroll: 1,
  initialSlide: 0,
  variableWidth: true,
  nextArrow: <SliderNextArrow />,
  prevArrow: <SliderPrevArrow />,
  responsive: [
    {
      breakpoint: 1340,
      settings: {
        slidesToShow: 9,
        slidesToScroll: 1,
        infinite: false,
        variableWidth: true,
      },
    },
    {
      breakpoint: 1220,
      settings: {
        slidesToShow: 8,
        slidesToScroll: 1,
        infinite: false,
        variableWidth: true,
      },
    },
  ],
};

const ProviderTypeItem = ({
  currentProviderType,
  specialist,
  providerType,
  setProviderType,
  setSpecialist,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['bottom', 'top', 'left', 'right']}
      containerClassName="react-tiny-popover-container z-40"
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowStyle={{ display: 'none' }}
        >
          <div className="flex flex-col gap-y-1 py-3 bg-white border rounded-lg shadow-2xl cursor-pointer">
            <div
              className={clsx('flex items-center gap-x-2 py-1 px-4 hover:bg-gray-100', {
                'text-main-green bg-light-green hover:bg-light-green':
                  currentProviderType === providerType.id && specialist === 0,
              })}
              onClick={() => {
                setProviderType(providerType.id);
                setIsPopoverOpen(false);
                setSpecialist(0);
              }}
            >
              <SubItemIcon />
              All
            </div>
            {providerType.specialists.map((sp) => (
              <div
                key={sp.id}
                className={clsx('flex items-center gap-x-2 py-1 px-4 hover:bg-gray-100', {
                  'text-main-green bg-light-green hover:bg-light-green': sp.id === specialist,
                })}
                onClick={() => {
                  setProviderType(providerType.id);
                  setIsPopoverOpen(false);
                  setSpecialist(sp.id);
                }}
              >
                <SubItemIcon />
                {sp.name}
              </div>
            ))}
          </div>
        </ArrowContainer>
      )}
      onClickOutside={() => setIsPopoverOpen(false)}
    >
      <div
        className={clsx(
          'flex flex-col items-center p-1 w-fit font-medium hover:bg-light-green rounded-lg cursor-pointer z-10',
          {
            'text-main-green': currentProviderType === providerType.id,
            'text-gray-400': currentProviderType !== providerType.id,
          },
        )}
        onClick={() => setIsPopoverOpen(true)}
      >
        <img
          src={getCDNImage(providerType.icon)}
          className={clsx('provider-type-icon', {
            active: currentProviderType === providerType.id,
          })}
        />
        {providerType.name}
      </div>
    </Popover>
  );
};

const Home = () => {
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const providerTypes = useAppSelector(selectProviderTypes) || [];
  const [isMapView, setIsMapView] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [searchOption, setSearchOption] = useState('');
  const [city, setCity] = useState(null);
  const [date, setDate] = useState(null);
  const [providerType, setProviderType] = useState(0);
  const [specialist, setSpecialist] = useState(0);
  const [providers, setProviders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [popularCities, setPopularCities] = useState([]);
  const [currentCity, setCurrentCity] = useState(null);
  const [providerTypeSearchKey, setProviderTypeSearchKey] = useState('');
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedProviderType, setSelectedProviderType] = useState(0);
  const [selectedDate, setSelectedDate] = useState(null);
  const [addressSearchKey, setAddressSearchKey] = useState('');
  const [searchCountries, setSearchCountries] = useState(['us', 'ca']);
  const [showMap, setShowMap] = useState(false);

  const [fetchProviders, { isLoading: isFetching }] = useFetchProvidersMutation();
  const [getGeoInfo] = useGetGeoInfoMutation();

  const modalRef = useRef(null);
  const panelRef = useRef(null);

  const handleClick = (e) => {
    if (
      modalRef.current &&
      !modalRef.current.contains(e.target) &&
      !panelRef.current.contains(e.target) &&
      !Object.values(e.target.classList).includes('address-suggestion')
    ) {
      setSearchOption('');
    }
  };

  const getProviders = (pageNumber) => {
    const data = {
      skip: (pageNumber - 1) * 8,
      limit: 8,
      searchKey,
    };
    if (city) {
      data.country = city.countryCode;
      data.state = city.stateCode;
      data.city = city.name;
    }
    if (providerType) {
      data.providerType = providerType;
    }
    if (specialist) {
      data.specialists = [specialist];
    }
    if (date) {
      data.weekday = moment(date).utc().format('dddd');
    }
    fetchProviders(data)
      .unwrap()
      .then((res) => {
        if (res.success) {
          setProviders([...res.providers]);
          setTotalPages(Math.ceil(res.totalCount / 8));
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handlePageChange = (e) => {
    setCurrentPage(e.selected + 1);
  };

  const handleLocationSelect = (address) => {
    setAddressSearchKey(address);
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        let cCode = 'US';
        if (address.split(',')[2].charAt(0).toLowerCase() === 'c') {
          cCode = 'CA';
        }
        setSelectedCity({
          name: address.split(',')[0],
          countryCode: cCode,
          latitude: latLng.lat,
          longitude: latLng.lng,
          stateCode: address.split(',')[1],
        });
      })
      .catch((error) => console.error('Error', error));
  };

  const handleSearch = () => {
    setProviderType(selectedProviderType);
    setSpecialist(0);
    setCity(selectedCity);
    setDate(selectedDate);
  };

  const handleReset = () => {
    setProviderType(0);
    setSpecialist(0);
    setCity(null);
    setSelectedCity(null);
    setSelectedProviderType(0);
    setDate(null);
    setSelectedDate(null);
    setShowMap(false);
    setIsMapView(false);
    setSearchKey('');
  };

  const mapCenter = useMemo(() => {
    if (city) {
      return { lat: city.latitude, lng: city.longitude };
    }
    if (currentCity) {
      return { lat: currentCity.latitude, lng: currentCity.longitude };
    }
    return { lat: 0, lng: 0 };
  }, [currentCity, city]);

  const GoogleMap = useMemo(
    () => (
      <Map
        center={mapCenter}
        withDetails
        markers={[
          {
            position: { lat: 34.0584, lng: -118.278 },
            images: [1, 2, 3],
            htmlContent: (
              <div className="p-2">
                <p className="text-sm font-semibold">Vijay Thomas</p>
                <p className="text-xs">This is so funny to implement on google map</p>
              </div>
            ),
          },
          {
            position: { lat: 34.0784, lng: -118.298 },
            images: [1, 2, 3],
            htmlContent: (
              <div className="p-2">
                <p className="text-sm font-semibold">Vijay Thomas</p>
                <p className="text-xs">This is so funny to implement on google map</p>
              </div>
            ),
          },
        ]}
      />
    ),
    [mapCenter],
  );

  useEffect(() => {
    getProviders(currentPage);
  }, [currentPage]);

  useEffect(() => {
    setIsLoading(true);
    setCurrentPage(1);
    getProviders(1);
    if (city) {
      setShowMap(true);
      setIsMapView(false);
    }
  }, [providerType, specialist, city, date, searchKey]);

  useEffect(() => {
    if (searchOption) {
      document.body.classList.add(`overflow-hidden`);
    } else {
      document.body.classList.remove(`overflow-hidden`);
    }
  }, [searchOption]);

  useEffect(() => {
    getGeoInfo()
      .unwrap()
      .then((res) => {
        if (res.success) {
          setCurrentCity({
            latitude: res.geo.ll[0],
            longitude: res.geo.ll[1],
            name: res.geo.city,
            stateCode: res.geo.region,
            countryCode: res.geo.country,
          });
          setSelectedCity({
            latitude: res.geo.ll[0],
            longitude: res.geo.ll[1],
            name: res.geo.city,
            stateCode: res.geo.region,
            countryCode: res.geo.country,
          });
          if (searchCountries.includes(res.geo.country.toLowerCase())) {
            setSearchCountries([res.geo.country.toLowerCase()]);
          }
          const cities = City.getCitiesOfState(res.geo.country, res.geo.region);
          const tempCities = [];
          cities.map((city) => {
            if (calcCrow(res.geo.ll[0], res.geo.ll[1], city.latitude, city.longitude) < 7) {
              tempCities.push({
                ...city,
                latitude: Number(city.latitude),
                longitude: Number(city.longitude),
              });
            }
          });

          if (!tempCities.length) {
            cities.map((city) => {
              if (calcCrow(res.geo.ll[0], res.geo.ll[1], city.latitude, city.longitude) < 15) {
                tempCities.push({
                  ...city,
                  latitude: Number(city.latitude),
                  longitude: Number(city.longitude),
                });
              }
            });
          }
          setPopularCities(tempCities);
        }
      });
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <div className="flex flex-col justify-center items-center">
      {searchOption || isLoading ? (
        <div className="fixed top-0 backdrop-brightness-50 w-full h-screen z-40" />
      ) : null}
      {isLoading ? (
        <div className="absolute flex items-center justify-center top-0 left-0 w-full h-full z-50">
          <ClipLoader
            color="#08A284"
            size={50}
          />
        </div>
      ) : null}
      {/* landing page header */}
      <div className="fixed top-0 flex items-center justify-between py-5 px-10 w-full bg-white border-b z-50">
        <img
          className="w-36 h-11 cursor-pointer"
          src={logoImg}
          alt="header-logo"
          onClick={handleReset}
        />
        <div className="flex items-center gap-x-4">
          <div className="w-[300px]">
            <Input
              placeholder="Search Providers..."
              Icon={(props) => <FaMagnifyingGlass {...props} />}
              value={searchKey}
              onChange={setSearchKey}
            />
          </div>
          <div
            ref={panelRef}
            className={clsx(
              'flex items-center gap-x-1 p-1 h-12 text-sm border border-main-gray rounded-full',
              {
                'bg-[#F6F6F6]': !!searchOption,
              },
            )}
          >
            <div
              className={clsx('cursor-pointer w-[100px] text-center', {
                'py-[9px] bg-white border border-main-gray rounded-full shadow-xl':
                  searchOption === 'location',
                'p-5': searchOption !== 'location',
              })}
              onClick={() => setSearchOption('location')}
            >
              Location
            </div>
            <div
              className={clsx('w-px h-4', {
                'bg-gray-400': !searchOption,
                'bg-[#F6F6F6]': !!searchOption,
              })}
            />
            <div
              className={clsx('cursor-pointer w-[100px] text-center', {
                'py-[9px] bg-white border border-main-gray rounded-full shadow-xl':
                  searchOption === 'date',
                'p-5': searchOption !== 'date',
              })}
              onClick={() => setSearchOption('date')}
            >
              Date
            </div>
            <div
              className={clsx('w-px h-4', {
                'bg-gray-400': !searchOption,
                'bg-[#F6F6F6]': !!searchOption,
              })}
            />
            <div
              className={clsx('cursor-pointer w-[100px] text-center', {
                'py-[9px] bg-white border border-main-gray rounded-full shadow-xl':
                  searchOption === 'speciality',
                'p-5': searchOption !== 'speciality',
              })}
              onClick={() => setSearchOption('speciality')}
            >
              Speciality
            </div>
            <div className="flex w-[90px]">
              <div
                className={clsx(
                  'flex items-center gap-x-1 ml-auto w-10 text-white bg-main-green rounded-full cursor-pointer',
                  {
                    'p-2.5 transition-all duration-300 w-full': searchOption,
                    'p-[13px]': !searchOption,
                  },
                )}
                onClick={() => {
                  setSearchOption('');
                  handleSearch();
                }}
              >
                <FaMagnifyingGlass color="#FFF" />
                {searchOption ? 'Search' : ''}
              </div>
            </div>
          </div>
          <Toggle
            width={250}
            defaultChecked={isMapView}
            labelMode
            options={['List View', 'Map View']}
            onChange={(value) => setIsMapView(value)}
          />
        </div>
        <div className="flex items-center gap-x-2">
          <div className="p-1 cursor-pointer rounded-full hover:bg-gray-200">
            <EarthIcon />
          </div>
          <div className="p-1 cursor-pointer rounded-full hover:bg-gray-200">
            <MenuIcon />
          </div>
        </div>
      </div>
      {/* landing page content */}
      <div
        className={clsx('w-full', {
          'pt-[187px]': showMap || isMapView,
          'pt-[92px]': !showMap && !isMapView,
        })}
      >
        <div className={clsx('mx-auto w-full', { 'max-w-8xl': !isMapView })}>
          <div
            className={clsx(
              'flex items-center justify-between gap-x-2 py-4 w-full max-w-8xl bg-white z-30',
              {
                'fixed top-[91px] left-0 right-0 mx-auto': showMap || isMapView,
              },
            )}
          >
            <div
              className={clsx('p-1 text-center hover:bg-light-green rounded-lg cursor-pointer', {
                'text-main-green': providerType === 0,
                'text-gray-400': providerType !== 0,
              })}
              onClick={() => {
                setProviderType(0);
                setSpecialist(0);
              }}
            >
              <BlockIcon className={clsx({ 'active-green': providerType === 0 })} />
              All
            </div>
            <div className="relative flex-1 px-3 max-w-[calc(100%_-_175px)]">
              <Slider {...sliderSettings}>
                {providerTypes.map((pType, index) => (
                  <ProviderTypeItem
                    key={`provier-type-${index}`}
                    providerType={pType}
                    specialist={specialist}
                    currentProviderType={providerType}
                    setProviderType={setProviderType}
                    setSpecialist={setSpecialist}
                  />
                ))}
              </Slider>
            </div>
            <Button
              color="dark"
              variant="outlined"
              fullWidth={false}
            >
              <FilterIcon />
              Filters
            </Button>
          </div>
          <main className={clsx({ 'flex gap-x-4': showMap && !isMapView })}>
            <div
              className={clsx('flex flex-col', {
                'w-1/2': showMap && !isMapView,
                hidden: isMapView,
              })}
            >
              {(isLoading || isFetching) && !providers.length ? (
                <div
                  className={clsx('grid gap-6', {
                    'grid-cols-4': !showMap,
                    'grid-cols-2': showMap,
                  })}
                >
                  {Array.from({ length: 8 }).map((_, index) => (
                    <div
                      key={index}
                      role="status"
                      className="flex flex-col space-y-8 animate-pulse"
                    >
                      <div className="flex items-center justify-center w-full aspect-square bg-gray-300 rounded">
                        <svg
                          className="w-10 h-10 text-gray-200 dark:text-gray-600"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 18"
                        >
                          <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                        </svg>
                      </div>
                      <div className="w-full">
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
                      </div>
                      <span className="sr-only">Loading...</span>
                    </div>
                  ))}
                </div>
              ) : null}
              <div
                className={clsx('grid gap-6', {
                  'grid-cols-4': !showMap,
                  'grid-cols-2': showMap,
                })}
              >
                {providers.length
                  ? providers.map((provider) => (
                      <UserItem
                        key={`provider-${provider.id}`}
                        user={provider}
                      />
                    ))
                  : null}
              </div>
              {!isLoading && !isFetching && !providers.length ? (
                <div className="text-center w-full">No Result</div>
              ) : null}
              <div className="mt-8 mb-3 mx-auto">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  onPageChange={handlePageChange}
                  forcePage={currentPage - 1}
                  pageRangeDisplayed={2}
                  marginPagesDisplayed={2}
                  pageCount={totalPages}
                  previousLabel="<"
                  renderOnZeroPageCount={null}
                  pageClassName="flex items-center justify-center w-[38px] h-[38px] border border-main-gray rounded-full font-semibold"
                  previousClassName={clsx(
                    'flex items-center justify-center w-[38px] h-[38px] border border-main-gray rounded-full font-semibold',
                    { 'point-events-none opacity-60 cursor-default': currentPage === 1 },
                  )}
                  nextClassName={clsx(
                    'flex items-center justify-center w-[38px] h-[38px] border border-main-gray rounded-full font-semibold',
                    { 'point-events-none opacity-60 cursor-default': currentPage === totalPages },
                  )}
                  breakClassName="flex items-center justify-center w-[38px] h-[38px] border border-main-gray rounded-full font-semibold"
                  activeClassName="text-main-green border-main-green cursor-default"
                  containerClassName="flex items-center gap-x-3.5"
                />
              </div>
            </div>
            {showMap || isMapView ? (
              <div className="relative flex-1">
                <div className="sticky top-[187px] h-[calc(100vh_-_187px)]">{GoogleMap}</div>
              </div>
            ) : null}
          </main>
        </div>
        {showMap || isMapView ? null : (
          <>
            <SecondSection />
            <ThirdSection />
            <FourthSection />
            <FifthSection />
            <Footer />
          </>
        )}
      </div>

      {/* modal for searching */}

      <div
        id="defaultModal"
        tabIndex="-1"
        aria-hidden="true"
        className={clsx(
          'fixed top-20 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto h-[calc(100%-1rem)] max-h-full',
          {
            hidden: !searchOption,
            block: !!searchOption,
          },
        )}
      >
        <div
          ref={modalRef}
          className={clsx('relative mx-auto p-5 w-full max-h-full bg-white rounded-xl shadow-2xl', {
            'max-w-sm': searchOption !== 'date',
            'max-w-[392px]': searchOption === 'date',
          })}
        >
          {searchOption === 'location' ? (
            <div>
              <PlacesAutocomplete
                value={addressSearchKey}
                googleCallbackName="initOne"
                searchOptions={{
                  componentRestrictions: { country: searchCountries },
                  types: ['locality', 'country'],
                }}
                onChange={(val) => setAddressSearchKey(val)}
                onSelect={handleLocationSelect}
              >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                  <div>
                    <div className="relative flex h-12 w-full text-sm bg-white border border-main-gray focus-within:border-main-green rounded-xl">
                      <FaMagnifyingGlass className="m-auto ml-3 text-main-dark" />
                      <input
                        {...getInputProps({
                          placeholder: 'Search your city...',
                          className: 'location-search-input',
                        })}
                        className="pl-2 w-full h-full bg-transparent focus:outline-none"
                      />
                      <AiOutlineClose
                        className="m-auto mr-3 text-main-dark cursor-pointer"
                        onClick={() => {
                          setAddressSearchKey('');
                          setSelectedCity(null);
                        }}
                      />
                      <div
                        className={clsx('absolute top-12 p-3 w-full rounded-lg shadow-2xl', {
                          'bg-white border border-gray-100': suggestions.length || loading,
                        })}
                      >
                        {loading && (
                          <div className="flex w-full items-center justify-center">
                            <ClipLoader
                              color="#08A284"
                              size={20}
                            />
                          </div>
                        )}
                        {suggestions.map((suggestion, index) => {
                          const className = suggestion.active
                            ? 'p-1.5 rounded-lg bg-main-medium-green'
                            : 'p-1.5 rounded-lg hover:bg-gray-400';
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                          return (
                            <div
                              key={`place-suggestion-${index}`}
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span className="address-suggestion">{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
              <div className="mt-6">
                <p className="font-semibold">Nearby areas</p>
                <div className="h-36 overflow-y-auto">
                  {popularCities.map((pCity, index) => (
                    <div
                      key={`popular-area-${index}`}
                      className={clsx('flex items-center gap-x-2 p-1 rounded-lg cursor-pointer', {
                        'text-main-green bg-light-green':
                          selectedCity &&
                          pCity.latitude === selectedCity.latitude &&
                          pCity.longitude === selectedCity.longitude,
                        'text-[#88879A] hover:bg-gray-100':
                          !selectedCity ||
                          pCity.latitude !== selectedCity.latitude ||
                          pCity.longitude !== selectedCity.longitude,
                      })}
                      onClick={() => setSelectedCity(pCity)}
                    >
                      <div
                        className={clsx('p-1 rounded-full', {
                          'bg-main-green':
                            selectedCity &&
                            pCity.latitude === selectedCity.latitude &&
                            pCity.longitude === selectedCity.longitude,
                          'bg-gray-200':
                            !selectedCity ||
                            pCity.latitude !== selectedCity.latitude ||
                            pCity.longitude !== selectedCity.longitude,
                        })}
                      >
                        <MarkerIcon
                          className={clsx({
                            'active-white':
                              selectedCity &&
                              pCity.latitude === selectedCity.latitude &&
                              pCity.longitude === selectedCity.longitude,
                          })}
                        />
                      </div>
                      <p className="text-[#88879A]">{pCity.name}</p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="mt-4">
                <p className="text-sm font-medium">Show in Map</p>
                <div className="mt-1 w-full h-48">
                  <Map
                    rounded
                    center={
                      selectedCity
                        ? { lat: selectedCity.latitude, lng: selectedCity.longitude }
                        : {
                            lat: currentCity.latitude,
                            lng: currentCity.longitude,
                          }
                    }
                  />
                </div>
              </div>
            </div>
          ) : null}
          {searchOption === 'date' ? (
            <div className="">
              <DatePicker
                selected={selectedDate}
                renderCustomHeader={({ monthDate, decreaseMonth, increaseMonth }) => (
                  <div className="flex items-center pl-4 my-2">
                    <span
                      className="react-datepicker__current-month text-lg font-semibold"
                      style={{ fontFamily: 'Poppins' }}
                    >
                      {monthDate.toLocaleString('en-US', {
                        month: 'short',
                        year: 'numeric',
                      })}
                    </span>
                    <div className="flex items-center gap-x-1 ml-2">
                      <button
                        aria-label="Previous Month"
                        className="relative p-1 rounded-full hover:bg-gray-100"
                        onClick={decreaseMonth}
                      >
                        <BsChevronLeft
                          color="#08A284"
                          className="text-base font-bold"
                        />
                      </button>
                      <button
                        aria-label="Next Month"
                        className="relative p-1 rounded-full hover:bg-gray-100"
                        onClick={increaseMonth}
                      >
                        <BsChevronRight
                          color="#08A284"
                          className="text-base font-bold"
                        />
                      </button>
                    </div>
                  </div>
                )}
                dayClassName={(date) =>
                  moment(date).isSame(moment(), 'day')
                    ? 'p-2 text-xl text-main-green bg-light-green rounded-full hover:bg-main-green hover:text-white hover:rounded-full'
                    : 'p-2 text-xl font-medium hover:rounded-full'
                }
                inline
                // showTimeInput
                onChange={(date) => setSelectedDate(date)}
              />
            </div>
          ) : null}
          {searchOption === 'speciality' ? (
            <div>
              <Input
                placeholder="Search speciality..."
                Icon={(props) => <FaMagnifyingGlass {...props} />}
                onChange={setProviderTypeSearchKey}
              />
              <div className="mt-6">
                <p className="font-semibold">Popular Specialities</p>
                <div className="flex flex-col gap-y-0.5 mt-1">
                  {providerTypes
                    .filter((pType) =>
                      pType.name.toLowerCase().includes(providerTypeSearchKey.toLowerCase()),
                    )
                    .map((pType, index) => (
                      <div
                        key={`popular-area-${index}`}
                        className={clsx('flex items-center gap-x-2 p-1 rounded-lg cursor-pointer', {
                          'text-main-green bg-light-green': pType.id === selectedProviderType,
                          'text-[#88879A] hover:bg-gray-100': pType.id !== selectedProviderType,
                        })}
                        onClick={() => setSelectedProviderType(pType.id)}
                      >
                        <div
                          className={clsx('p-1 rounded-full', {
                            'bg-main-green': pType.id === selectedProviderType,
                            'bg-gray-200': pType.id !== selectedProviderType,
                          })}
                        >
                          <ClinicIcon
                            className={clsx({ 'active-white': pType.id === selectedProviderType })}
                          />
                        </div>
                        <p className="">{pType.name}</p>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Home;
