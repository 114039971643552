import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-datepicker/dist/react-datepicker.css';

import Header from './Header';
import { useAppSelector } from 'hooks/store';
import { selectCurrentUser } from 'store/auth.slice';

const contextClass = {
  success: 'bg-main-green',
  error: 'bg-red-500',
  info: 'bg-gray-600',
  warning: 'bg-orange-400',
  default: 'bg-indigo-600',
  dark: 'bg-white-600 font-gray-300',
};

const Layout = ({ children }) => {
  const user = useAppSelector(selectCurrentUser) || null;
  const location = useLocation();

  const showHeader = useMemo(() => {
    if (
      location.pathname === '/' ||
      location.pathname === '/login' ||
      location.pathname === '/register' ||
      location.pathname === '/verify'
    ) {
      return false;
    }
    return true;
  }, [location]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 1000);
  }, []);

  return (
    <div className="flex w-full h-full min-h-screen">
      <ToastContainer
        pauseOnHover
        theme="colored"
        bodyClassName="text-sm"
        toastClassName={({ type }) =>
          contextClass[type || 'default'] +
          ' relative flex mt-3 py-3 px-2 min-h-10 rounded justify-between overflow-hidden cursor-pointer'
        }
      />
      {showHeader ? <Header user={user} /> : null}
      <div className="flex-1">{children}</div>
    </div>
  );
};

export default Layout;
