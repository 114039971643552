import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { setUserLoggedOut } from '../store/auth.slice';

const baseQuery = fetchBaseQuery({
  // eslint-disable-next-line no-undef
  baseUrl: process.env.REACT_APP_API_BASE_URI,
  prepareHeaders: (headers, { getState }) => {
    const { token } = getState().auth;
    if (token) {
      headers.set('authorization', ` Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithAuthDispatch = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (
    (result.error && result.error.status === 401) ||
    (api.endpoint === 'logoutUser' && result.meta?.response?.status === 200)
  ) {
    api.dispatch(setUserLoggedOut());
    // api.dispatch(setInitialState());
  }
  return result;
};

export const restApi = createApi({
  baseQuery: baseQueryWithAuthDispatch,
  tagTypes: ['Request', 'Solution'],
  endpoints: (builder) => ({
    // api - auth and status sync
    getGeoInfo: builder.mutation({
      query: () => {
        const url = '/api/public/location';
        return {
          url,
          method: 'GET',
          mode: 'cors',
        };
      },
    }),
    login: builder.mutation({
      query: (request) => {
        const url = '/api/public/login';
        return {
          url,
          method: 'POST',
          body: request,
          mode: 'cors',
        };
      },
    }),
    getSyncToken: builder.mutation({
      query: () => {
        const url = '/api/private/sync-token';
        return {
          url,
          method: 'GET',
          mode: 'cors',
        };
      },
    }),
    requestVerificationCode: builder.mutation({
      query: () => {
        const url = '/api/private/request';
        return {
          url,
          method: 'POST',
          body: {},
          mode: 'cors',
        };
      },
    }),
    verfiyCode: builder.mutation({
      query: (request) => {
        const url = '/api/public/verify';
        return {
          url,
          method: 'POST',
          body: request,
          mode: 'cors',
        };
      },
    }),
    uploadSignature: builder.mutation({
      query: (request) => {
        const url = '/api/private/user/signature';
        return {
          url,
          method: 'POST',
          body: request,
          mode: 'cors',
        };
      },
    }),
    getTermsAndConditions: builder.mutation({
      query: () => {
        const url = '/api/utility/terms-and-conditions';
        return {
          url,
          method: 'GET',
          mode: 'cors',
        };
      },
    }),
    forgotPassword: builder.mutation({
      query: (request) => {
        const url = '/api/public/forgot-password';
        return {
          url,
          method: 'POST',
          body: request,
          mode: 'cors',
        };
      },
    }),
    resetPassword: builder.mutation({
      query: (request) => {
        const url = '/api/public/reset-password';
        return {
          url,
          method: 'POST',
          body: request,
          mode: 'cors',
        };
      },
    }),

    // api - get properties
    fetchProviderTypes: builder.mutation({
      query: () => {
        const url = '/api/utility/provider-types';
        return {
          url,
          method: 'GET',
          mode: 'cors',
        };
      },
    }),
    fetchLanguages: builder.mutation({
      query: () => {
        const url = '/api/utility/languages';
        return {
          url,
          method: 'GET',
          mode: 'cors',
        };
      },
    }),
    addLanguageMatch: builder.mutation({
      query: (request) => {
        const url = '/api/private/language-match';
        return {
          url,
          method: 'POST',
          body: request,
          mode: 'cors',
        };
      },
    }),
    deleteLanguageMatch: builder.mutation({
      query: (id) => {
        const url = `/api/private/language-match/${id}`;
        return {
          url,
          method: 'DELETE',
          mode: 'cors',
        };
      },
    }),

    // api - appointments
    fetchBookings: builder.mutation({
      query: (request) => {
        const url = '/api/private/bookings';
        return {
          url,
          method: 'POST',
          body: request,
          mode: 'cors',
        };
      },
    }),
    fetchBookingsByUser: builder.mutation({
      query: (request) => {
        const { userId, ...payload } = request;
        const url = `/api/private/bookings/${userId}`;
        return {
          url,
          method: 'POST',
          body: payload,
          mode: 'cors',
        };
      },
    }),

    // api - user
    updateUser: builder.mutation({
      query: (request) => {
        const { id, ...payload } = request;
        const url = `/api/private/user/${id}`;
        return {
          url,
          method: 'PUT',
          body: payload,
          mode: 'cors',
        };
      },
    }),
    uploadAvatar: builder.mutation({
      query: (request) => {
        const { id, data } = request;
        const url = `/api/private/upload-avatar/${id}`;
        return {
          url,
          method: 'POST',
          body: data,
          mode: 'cors',
        };
      },
    }),

    // api - providers
    fetchProviders: builder.mutation({
      query: (request) => {
        const url = `/api/utility/providers`;
        return {
          url,
          method: 'POST',
          body: request,
          mode: 'cors',
        };
      },
    }),
    fetchFavoriteProviders: builder.mutation({
      query: (userId) => {
        const url = `/api/private/favorite-providers/${userId}`;
        return {
          url,
          method: 'GET',
          mode: 'cors',
        };
      },
    }),
    fetchProvider: builder.mutation({
      query: (userId) => {
        const url = `/api/utility/providers/${userId}`;
        return {
          url,
          method: 'GET',
          mode: 'cors',
        };
      },
    }),
    fetchProviderTimeslots: builder.mutation({
      query: (request) => {
        const url = `/api/utility/available-timeslots`;
        return {
          url,
          method: 'POST',
          body: request,
          mode: 'cors',
        };
      },
    }),
    addNewService: builder.mutation({
      query: (request) => {
        const url = `/api/private/service`;
        return {
          url,
          method: 'POST',
          body: request,
          mode: 'cors',
        };
      },
    }),
    updateService: builder.mutation({
      query: (request) => {
        const { id, ...payload } = request;
        const url = `/api/private/service/${id}`;
        return {
          url,
          method: 'PUT',
          body: payload,
          mode: 'cors',
        };
      },
    }),
    deleteService: builder.mutation({
      query: (id) => {
        const url = `/api/private/service/${id}`;
        return {
          url,
          method: 'DELETE',
          mode: 'cors',
        };
      },
    }),
    addNewServiceItem: builder.mutation({
      query: (request) => {
        const { serviceId, ...payload } = request;
        const url = `/api/private/service/${serviceId}`;
        return {
          url,
          method: 'POST',
          body: payload,
          mode: 'cors',
        };
      },
    }),
    updateServiceItem: builder.mutation({
      query: (request) => {
        const { id, ...payload } = request;
        const url = `/api/private/service-item/${id}`;
        return {
          url,
          method: 'PUT',
          body: payload,
          mode: 'cors',
        };
      },
    }),
    deleteServiceItem: builder.mutation({
      query: (id) => {
        const url = `/api/private/service-item/${id}`;
        return {
          url,
          method: 'DELETE',
          mode: 'cors',
        };
      },
    }),
    addPackage: builder.mutation({
      query: (request) => {
        const { id, ...payload } = request;
        const url = `/api/private/provider-package/${id}`;
        return {
          url,
          method: 'POST',
          body: payload,
          mode: 'cors',
        };
      },
    }),
    deletePackage: builder.mutation({
      query: (id) => {
        const url = `/api/private/provider-package/${id}`;
        return {
          url,
          method: 'DELETE',
          mode: 'cors',
        };
      },
    }),
    purchasePackage: builder.mutation({
      query: (request) => {
        const url = `/api/private/package/purchase`;
        return {
          url,
          method: 'POST',
          body: request,
          mode: 'cors',
        };
      },
    }),
    fetchPurchasedPackage: builder.mutation({
      query: (request) => {
        const { id, ...payload } = request;
        const url = `/api/private/package/purchased/${id}`;
        return {
          url,
          method: 'POST',
          body: payload,
          mode: 'cors',
        };
      },
    }),
    activateProviderPackage: builder.mutation({
      query: (id) => {
        const url = `/api/private/activate-package/${id}`;
        return {
          url,
          method: 'GET',
          mode: 'cors',
        };
      },
    }),
    inactivateProviderPackage: builder.mutation({
      query: (id) => {
        const url = `/api/private/inactivate-package/${id}`;
        return {
          url,
          method: 'GET',
          mode: 'cors',
        };
      },
    }),
  }),
});

export const {
  useGetGeoInfoMutation,
  useLoginMutation,
  useGetSyncTokenMutation,
  useRequestVerificationCodeMutation,
  useVerfiyCodeMutation,
  useUploadSignatureMutation,
  useGetTermsAndConditionsMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useFetchProviderTypesMutation,
  useFetchBookingsMutation,
  useFetchBookingsByUserMutation,
  useFetchFavoriteProvidersMutation,
  useFetchProvidersMutation,
  useFetchProviderMutation,
  useFetchProviderTimeslotsMutation,
  useFetchLanguagesMutation,
  useAddNewServiceMutation,
  useAddNewServiceItemMutation,
  useUpdateServiceMutation,
  useUpdateServiceItemMutation,
  useDeleteServiceMutation,
  useDeleteServiceItemMutation,
  useUpdateUserMutation,
  useAddPackageMutation,
  useDeletePackageMutation,
  usePurchasePackageMutation,
  useFetchPurchasedPackageMutation,
  useActivateProviderPackageMutation,
  useInactivateProviderPackageMutation,
  useUploadAvatarMutation,
  useAddLanguageMatchMutation,
  useDeleteLanguageMatchMutation,
} = restApi;
