/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

import emptyImg from 'assets/empty.png';
import { useAppSelector } from 'hooks/store';
import { selectFavoriteProviers } from 'store/data.slice';
import UserItem from 'components/UserItem';

const Favorites = ({ user }) => {
  const favorieProviders = useAppSelector(selectFavoriteProviers) || [];

  return (
    <div
      id="cp-favorites"
      className="relative w-full h-full flex flex-col pt-10 pb-4 px-4 overflow-hidden"
    >
      {favorieProviders.length ? (
        <div className="grid grid-cols-5 gap-4 overflow-auto">
          {favorieProviders.map((provider) => (
            <UserItem
              key={`favorite-provider-${provider.id}`}
              user={{ ...provider, isFavorite: true }}
            />
          ))}
        </div>
      ) : (
        <div className="absolute max-w-sm h-fit top-0 bottom-0 right-0 left-0 m-auto">
          <img
            className="w-full"
            src={emptyImg}
            alt="empty"
          />
          <p className="text-center text-lg">No Favorite Providers</p>
        </div>
      )}
    </div>
  );
};

export default Favorites;
