import React, { useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import Slider from 'react-slick';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';

import clsx from 'clsx';
import { ClipLoader } from 'react-spinners';

import sampleImg from 'assets/sample_provider.png';
import markerImg from 'assets/marker.png';

const SliderPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} items-center justify-center h-full z-10`}
      style={{ ...style, display: 'flex', left: '8px' }}
    >
      <div
        className="relative p-1 text-sm text-main-dark bg-white border-main-gray rounded-full z-20 hover:text-main-dark hover:bg-gray-100"
        onClick={onClick}
      >
        <BsChevronLeft />
      </div>
    </div>
  );
};

const SliderNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} items-center justify-center h-full`}
      style={{ ...style, display: 'flex', right: '8px' }}
    >
      <div className="relative flex items-center h-full">
        <div
          className="relative p-1 text-sm text-main-dark bg-white rounded-full shadow-2xl z-20 hover:text-main-dark hover:bg-gray-100"
          onClick={onClick}
        >
          <BsChevronRight />
        </div>
      </div>
    </div>
  );
};

const sliderSettings = {
  dots: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <SliderNextArrow />,
  prevArrow: <SliderPrevArrow />,
  dotsClass: 'slick-dots bottom-2.5',
};

const Marker = ({ active = false, varmap, children, position, title = '', onClick }) => {
  const markerRef = useRef();
  const rootRef = useRef();

  useEffect(() => {
    if (!rootRef.current) {
      // const google = window.google;
      const container = document.createElement('div');
      rootRef.current = createRoot(container);

      const params = { position, content: container };
      if (title) {
        params.title = title;
      }

      markerRef.current = new window.google.maps.marker.AdvancedMarkerElement(params);
    }
  }, []);

  useEffect(() => {
    rootRef.current.render(children);
    markerRef.current.position = position;
    markerRef.current.map = varmap;
    if (active) {
      markerRef.current.zIndex = 999999;
    } else {
      markerRef.current.zIndex = 9999;
    }
    const listener = markerRef.current.addListener('click', onClick);
    return () => listener.remove();
  }, [varmap, position, children, active]);
};

const MarkerContent = ({ showDetails, data, onCloseView }) => {
  const { images, htmlContent } = data;
  return (
    <Popover
      isOpen={showDetails}
      positions={['bottom', 'top', 'left', 'right']}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowStyle={{ display: 'none' }}
        >
          <div className="mt-1 w-56 bg-white rounded-lg">
            <div className="w-56 h-56">
              {images ? (
                <Slider {...sliderSettings}>
                  <div className="flex items-center justify-center aspect-square">
                    <img src={sampleImg} />
                  </div>
                  <div className="flex items-center justify-center aspect-square">
                    <img src={sampleImg} />
                  </div>
                </Slider>
              ) : null}
            </div>
            {htmlContent}
          </div>
        </ArrowContainer>
      )}
      onClickOutside={onCloseView}
    >
      <div
        className={clsx('pt-1 pb-0.5 px-2 rounded-full shadow-black cursor-pointer', {
          'bg-main-green text-white border border-gray-300 shadow-xl': showDetails,
          'bg-white border border-main-gray shadow-md hover:bg-main-gray hover:border-gray-300':
            !showDetails,
        })}
      >
        <span className="text-sm font-semibold">{`$100`}</span>
      </div>
    </Popover>
  );
};

const Map = ({ center, rounded, withDetails = false, zoom = 12, markers = [] }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [map, setMap] = useState();
  const [selectedMarkerId, setSelectedMarkerId] = useState(null);
  const ref = useRef();

  useEffect(() => {
    const mapOptions = {
      mapId: 'careplatform-map',
      center,
      zoom,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      fullscreenControl: false,
      zoomControl: true,
      zoomControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_TOP,
      },
    };

    setMap(new window.google.maps.Map(ref.current, mapOptions));
  }, [center, zoom]);

  useEffect(() => {
    if (map) {
      map.addListener('tilesloaded', function () {
        console.log('map is loaded');
        setIsLoading(false);
      });
    }
  });

  return (
    <div className="relative w-full h-full">
      {isLoading ? (
        <div className="absolute flex items-center justify-center top-0 left-0 w-full h-full z-50">
          <ClipLoader
            color="#08A284"
            size="30"
          />
        </div>
      ) : null}
      <div
        ref={ref}
        className={clsx('w-full h-full', { 'rounded-xl': rounded })}
      >
        {map
          ? markers.map((marker, index) => (
              <div key={`google-map-marker-${index}`}>
                <Marker
                  varmap={map}
                  position={marker.position}
                  title={marker.title || ''}
                  active={index === selectedMarkerId}
                  onClick={() => setSelectedMarkerId(index)}
                >
                  {withDetails ? (
                    <MarkerContent
                      showDetails={index === selectedMarkerId}
                      data={marker}
                      onCloseView={() => setSelectedMarkerId(null)}
                    />
                  ) : (
                    <img
                      src={markerImg}
                      className="w-8 h-8"
                    />
                  )}
                </Marker>
              </div>
            ))
          : null}
      </div>
    </div>
  );
};

export default React.memo(Map);
