import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Input from 'components/Input';
import Button from 'components/Button';
import { useLoginMutation } from 'api/rest.api';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { selectCurrentUser, setAuthenticatedUser } from 'store/auth.slice';
import { selectRedirectPath, setRedirectPath } from 'store/data.slice';
import { UserRole, UserStatus } from 'utils/labels';

import logoImg from 'assets/logo.png';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectCurrentUser) || null;
  const redirectPath = useAppSelector(selectRedirectPath) || null;
  const [error, setError] = useState('');

  const [loginUser, { isLoading }] = useLoginMutation();

  const onRedirectUser = (user) => {
    if (user.status === UserStatus.PENDING) {
      navigate('/verify');
    }
    if (user.status === UserStatus.ACTIVE) {
      if (redirectPath && user.role === UserRole.CUSTOMER) {
        dispatch(setRedirectPath(null));
        navigate(redirectPath);
      } else {
        navigate('/dashboard');
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    const phone = data.get('phone').replace(/[^A-Z0-9]+/ig, '');
    const password = data.get('password');

    if (!phone || phone.length < 10 || !password) {
      return setError('Please enter correct phone number and password.');
    }

    await loginUser({ phone, password })
      .unwrap()
      .then((res) => {
        dispatch(setAuthenticatedUser(res));
      })
      .catch((err) => {
        console.log(err);
        setError('Your entered incorrect phone number or password.');
      });
  };

  useEffect(() => {
    if (user) {
      onRedirectUser(user);
    }
  }, [user]);

  return (
    <div className="flex w-full h-full min-h-screen">
      <div className="flex flex-col m-auto items-center w-full max-w-md">
        <img
          src={logoImg}
          className="w-40 h-12 cursor-pointer"
          onClick={() => navigate('/')}
        />
        <p className="mt-5 text-4xl font-semibold">Sign In</p>
        <p className="mt-5 text-base text-gray-500 text-center">
          Please enter the registered phone number & Password for your User account
        </p>
        <form
          className="flex flex-col gap-y-2 mt-5 w-full"
          onSubmit={handleSubmit}
        >
          {error ? <span className="text-sm text-center text-red-500">{error}</span> : null}
          <Input
            name="phone"
            type="phone"
            placeholder="Phone number"
          />
          <Input
            name="password"
            type="password"
            placeholder="Password"
          />
          <p
            className="text-right cursor-pointer"
            onClick={() => navigate('/forgot-password')}
          >
            Forgot password?
          </p>
          <Button
            fullWidth
            loading={isLoading}
            cssClass="mt-4"
            type="submit"
            variant="contained"
          >
            Sign In
          </Button>
          <div className="flex gap-x-2 justify-center mt-2">
            <p className="text-base text-gray-500">{"Don't have an account?"}</p>
            <p
              className="text-main-green underline underline-offset-2 cursor-pointer"
              onClick={() => navigate('/register')}
            >
              Sign Up
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
