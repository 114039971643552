import React from 'react';

const Checkbox = ({ label, defaultChecked = false, onChange }) => {
  return (
    <div className="flex items-center">
      <input
        id="cp-checkbox"
        type="checkbox"
        defaultChecked={defaultChecked}
        value=""
        className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded-xl accent-main-green"
        onChange={onChange}
      />
      {label ? (
        <label
          htmlFor="cp-checkbox"
          className="ml-2"
        >
          {label}
        </label>
      ) : null}
    </div>
  );
};

export default Checkbox;
