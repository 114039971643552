import React from 'react';
import PhoneInput from 'react-phone-number-input/input';
import clsx from 'clsx';

const Input = ({
  multi = false,
  rounded = false,
  withCountryCode = false,
  Icon = null,
  rows = 1,
  name,
  type = 'text',
  value,
  defaultValue,
  placeholder,
  onChange,
}) => {
  if (type === 'phone') {
    return (
      <div
        className={clsx(
          'flex px-3 w-full h-12 border border-main-gray bg-white focus-within:border-main-green',
          {
            'rounded-full': rounded,
            'rounded-xl': !rounded,
          },
        )}
      >
        {Icon ? <Icon className="m-auto text-main-dark" /> : null}
        {withCountryCode ? (
          <div className="flex items-center justify-center w-12 h-full border-r">+1</div>
        ) : null}
        <PhoneInput
          className={clsx('w-full bg-transparent focus:outline-none', {
            'pl-2': withCountryCode || !!Icon,
          })}
          name={name}
          country="US"
          value={value || defaultValue}
          placeholder={placeholder}
          onChange={(val) => onChange?.(val)}
        />
      </div>
    );
  }

  if (multi) {
    return (
      <div
        className={clsx(
          'flex items-start py-3 px-3 w-full bg-white border border-main-gray focus-within:border-main-green',
          {
            'rounded-xl': !rounded,
            'rounded-full': rounded,
          },
        )}
      >
        {Icon ? <Icon className="mx-auto text-main-dark" /> : null}
        <textarea
          className={clsx('w-full h-full bg-transparent focus:outline-none', { 'pl-2': !!Icon })}
          name={name}
          rows={rows}
          defaultValue={defaultValue}
          value={value}
          placeholder={placeholder}
          onChange={(e) => onChange?.(e.target.value)}
        />
      </div>
    );
  }

  return (
    <div
      className={clsx(
        'flex px-3 h-12 w-full bg-white border border-main-gray focus-within:border-main-green',
        {
          'rounded-xl': !rounded,
          'rounded-full': rounded,
        },
      )}
    >
      {Icon ? <Icon className="m-auto text-main-dark" /> : null}
      <input
        className={clsx('w-full h-full bg-transparent focus:outline-none', { 'pl-2': !!Icon })}
        name={name}
        type={type}
        defaultValue={defaultValue}
        value={value}
        placeholder={placeholder}
        onChange={(e) => onChange?.(e.target.value)}
      />
    </div>
  );
};

export default Input;
