import React from 'react';

import Button from 'components/Button';
import Input from 'components/Input';
import Select from 'components/Select';

import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg';
import consultImg from 'assets/consult.png';

const mockCities = [
  { value: '1', label: 'New York' },
  { value: '2', label: 'Los Angeles' }
];

const FourthSection = () => {
  return (
    <div className="flex my-10 bg-light-green w-full">
      <div
        className="w-1/2 bg-center bg-no-repeat bg-cover rounded-r-3xl"
        style={{ backgroundImage: `url(${consultImg})` }}
      />
      <div className="flex-1 p-10">
        <p className="text-3xl font-medium">We are here to help!</p>
        <p className="text-gray-500">
          Confidential, safe and quick treatment by Careplatform specialists.
        </p>
        <p className="mt-8 text-2xl font-medium">Fill out the form & get an</p>
        <p className="text-2xl font-semibold text-main-green">quick call back in 15 minutes</p>
        <div className="flex items-center gap-x-2 mt-1">
          or
          <div className="flex items-center gap-x-1 py-0.5 px-3 w-fit text-lg bg-white rounded-full border border-medium-green">
            <PhoneIcon />
            +1 8111-874-512
          </div>
        </div>
        <form className="flex flex-col gap-y-4 mt-6 max-w-sm">
          <div className="flex flex-col gap-y-1">
            <label className="text-sm">Full name*</label>
            <Input
              name="name"
              type="text"
              placeholder="Enter here"
            />
          </div>
          <div className="flex flex-col gap-y-1">
            <label className="text-sm">Phone number*</label>
            <Input
              name="phone"
              type="phone"
              placeholder="Enter here"
            />
          </div>
          <div className="flex flex-col gap-y-1">
            <label className="text-sm">City*</label>
            <Select
              name="city"
              options={mockCities}
              placeholder="Please select"
            />
          </div>
          <Button
            // loading={isLoading}
            fullWidth={false}
            cssClass="mt-3 px-6"
            type="submit"
            variant="contained"
          >
            Book appointment
          </Button>
        </form>
      </div>
    </div>
  );
};

export default FourthSection;
