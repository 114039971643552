import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import Input from 'components/Input';
import Button from 'components/Button';
import { useForgotPasswordMutation } from 'api/rest.api';

import logoImg from 'assets/logo.png';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [error, setError] = useState('');

  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    const phone = data.get('phone').replace(/[^A-Z0-9]+/ig, '');

    if (!phone || phone.length < 10) {
      return setError('Please input correct phone number');
    }
    await forgotPassword({ phone })
      .unwrap()
      .then(() => {
        toast('Your request has been sent successfully. Please check your SMS or Email.', {
          type: 'success',
        });
      })
      .catch((err) => {
        if (err.status === 404) {
          toast('No account with your phone number.', {
            type: 'error',
          });
        } else {
          toast('Something wrong. Please try again later.', {
            type: 'error',
          });
        }
      });
  };
  return (
    <div className="flex w-full h-full">
      <div className="flex flex-col m-auto items-center w-full max-w-md">
        <img
          src={logoImg}
          className="w-40 h-12"
        />
        <p className="mt-5 text-3xl font-semibold">Forgot Your Password?</p>
        <p className="mt-5 text-base text-gray-500 text-center">
          Please enter phone number for your User account
        </p>
        <form
          className="flex flex-col gap-y-2 mt-5 w-full"
          onSubmit={handleSubmit}
        >
          {error ? <span className="text-sm text-center text-red-500">{error}</span> : null}
          <Input
            name="phone"
            type="phone"
            placeholder="Phone number"
          />
          <Button
            loading={isLoading}
            cssClass="mt-4"
            type="submit"
            variant="contained"
          >
            Send
          </Button>
          <p
            className="text-main-green underline underline-offset-2 text-center cursor-pointer"
            onClick={() => navigate('/login')}
          >
            Sign In
          </p>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
