import React, { useMemo } from 'react';
import moment from 'moment';
import clsx from 'clsx';

import Avatar from './Avatar';
import Button from './Button';
import { useAppSelector } from 'hooks/store';
import { selectUserRole } from 'store/auth.slice';
import { AppointmentType, BookingStatus, UserRole } from 'utils/labels';

const Actions = ({ userRole, booking }) => {
  switch (booking.status) {
    case BookingStatus.ACCEPTED:
      return (
        <div className="flex gap-x-1">
          {booking.type === AppointmentType.VIRTUAL ? <Button>Call</Button> : null}
          {userRole === UserRole.CUSTOMER &&
          booking.type === AppointmentType.CUSTOMER_LOCATION &&
          !booking.customerConnected ? (
            <Button cssClass="text-sm">Ready</Button>
          ) : null}
          {userRole === UserRole.CUSTOMER &&
          booking.type === AppointmentType.PROVIDER_LOCATION &&
          !booking.customerConnected ? (
            <Button cssClass="text-sm">Arrive</Button>
          ) : null}
          {userRole === UserRole.CUSTOMER &&
          booking.type === AppointmentType.CUSTOMER_LOCATION &&
          booking.customerConnected &&
          !booking.callStartedAt ? (
            <Button cssClass="text-sm">Start</Button>
          ) : null}
          {[UserRole.PROVIDER, UserRole.CLINIC_MEMBER].includes(userRole) ? (
            <Button cssClass="text-sm">Complete</Button>
          ) : null}
          <Button
            color="danger"
            cssClass="text-sm"
          >
            Cancel
          </Button>
        </div>
      );
    case BookingStatus.PENDING:
      return (
        <div className="flex gap-x-1">
          {[UserRole.PROVIDER, UserRole.CLINIC_MEMBER].includes(userRole) ? (
            <Button cssClass="text-sm">Accept</Button>
          ) : null}
          <Button
            color="danger"
            cssClass="text-sm"
          >
            {userRole === UserRole.CUSTOMER ? 'Cancel' : 'Reject'}
          </Button>
        </div>
      );
    case BookingStatus.COMPLETED:
      return (
        <div className="flex gap-x-1">
          {userRole === UserRole.CUSTOMER ? <Button cssClass="text-sm">Book Again</Button> : null}
          <Button
            color="warning"
            cssClass="text-sm"
          >
            Review
          </Button>
          <Button
            variant="outlined"
            cssClass="text-sm"
          >
            Receipt
          </Button>
          <Button cssClass="text-sm">Call Record</Button>
        </div>
      );
    case BookingStatus.CANCELLED_CUSTOMER:
    case BookingStatus.CANCELLED_PROVIDER:
    case BookingStatus.REJECTED_CUSTOMER:
    case BookingStatus.REJECTED_PROVIDER:
      return (
        <div className="flex gap-x-1">
          <Button
            variant="outlined"
            cssClass="text-sm"
          >
            Receipt
          </Button>
          <Button cssClass="text-sm">Reason</Button>
        </div>
      );
    default:
      return (
        <Button
          variant="outlined"
          cssClass="text-sm"
        >
          Receipt
        </Button>
      );
  }
};

const BookingItem = ({ booking }) => {
  const role = useAppSelector(selectUserRole);

  const appointmentType = useMemo(() => {
    switch (booking.type) {
      case AppointmentType.VIRTUAL:
        return 'Virtual';
      case AppointmentType.PROVIDER_LOCATION:
        return 'Provider Location';
      case AppointmentType.CUSTOMER_LOCATION:
        return 'Customer Location';
      default:
        return 'N|A';
    }
  }, [booking.type]);

  const bookingStatus = useMemo(() => {
    switch (booking.status) {
      case BookingStatus.ACCEPTED:
        return 'Accepted';
      case BookingStatus.PENDING:
        return 'Pending';
      case BookingStatus.INCOMPLETE:
        return 'Incomplete';
      case BookingStatus.COMPLETED:
        return 'Completed';
      case BookingStatus.CANCELLED_CUSTOMER:
        return 'Cancelled by customer';
      case BookingStatus.CANCELLED_PROVIDER:
        return 'Cancelled by provider';
      case BookingStatus.REJECTED_CUSTOMER:
        return 'Pending appointment rejected by customer';
      case BookingStatus.REJECTED_PROVIDER:
        return 'Pending appointment rejected by provider';
      default:
        return '';
    }
  }, [booking.status]);

  return (
    <div className="flex flex-col bg-white rounded-lg shadow-lg">
      <div className="flex items-center justify-between py-2 px-3">
        <div className="flex items-center gap-x-2">
          <div
            className={clsx('px-1.5 py-0.5 text-xs text-white rounded-lg', {
              'bg-cyan-600': booking.type === AppointmentType.VIRTUAL,
              'bg-teal-600': booking.type === AppointmentType.PROVIDER_LOCATION,
              'bg-emerald-600': booking.type === AppointmentType.CUSTOMER_LOCATION,
            })}
          >
            {appointmentType}
          </div>
          {booking.packageAppointmentId ? (
            <span className="px-1.5 py-0.5 text-xs text-white rounded-lg bg-orange-500">
              Package
            </span>
          ) : null}
        </div>
        <div className="text-xs">
          {moment.utc(`${booking.date} ${booking.time}`).local().format('MMM DD, YYYY hh:mm A')}
        </div>
      </div>
      <div className="p-3 border-y">
        <div className="flex items-center justify-between">
          {role !== UserRole.CUSTOMER ? (
            <div className="flex items-center gap-x-2">
              <Avatar
                hasIndicator
                size="md"
                user={booking.customer}
              />
              <div>
                <p>{`${booking.customer.name} ${booking.customer.lastName || ''}`}</p>
                <span className="text-xs text-gray-500 capitalize">
                  {booking.customer.role.replace('-', ' ')}
                </span>
              </div>
            </div>
          ) : null}
          {role !== UserRole.PROVIDER || role !== UserRole.CLINIC_MEMBER ? (
            <div className="flex items-center gap-x-2">
              <Avatar
                hasIndicator
                size="md"
                user={booking.provider}
              />
              <div>
                <p>{`${booking.provider.name} ${booking.provider.lastName || ''}`}</p>
                <span className="text-xs text-gray-500 capitalize">
                  {booking.provider.role.replace('-', ' ')}
                </span>
              </div>
            </div>
          ) : null}
        </div>
        <div className="mt-1 text-sm">
          <p>
            {booking.price
              ? `${booking.service} - ${booking.duration}mins - $${booking.price}`
              : `${booking.service} - ${booking.duration}mins (${booking.packageName})`}
          </p>
          <p
            className={clsx({
              'text-emerald-600': booking.status === BookingStatus.ACCEPTED,
              'text-red-500': [
                BookingStatus.CANCELLED_CUSTOMER,
                BookingStatus.CANCELLED_PROVIDER,
                BookingStatus.REJECTED_CUSTOMER,
                BookingStatus.REJECTED_PROVIDER,
                BookingStatus.INCOMPLETE,
              ].includes(booking.status),
              'text-cyan-600': booking.status === BookingStatus.COMPLETED,
              'text-yellow-600': booking.status === BookingStatus.PENDING,
            })}
          >
            {bookingStatus}
          </p>
        </div>
      </div>
      <div className="py-2 px-3">
        <Actions
          userRole={role}
          booking={booking}
          onCall={() => {}}
        />
      </div>
    </div>
  );
};

export default BookingItem;
