import React from 'react';
import clsx from 'clsx';

const Tabs = ({ currentTab, options, onChange }) => {
  return (
    <div className="flex items-center gap-x-4">
      {options.map((option, index) => (
        <div
          key={index}
          className={clsx('w-36 text-2xl font-semibold text-center', {
            'py-2 px-4 text-main-green border-b-2 border-main-green bg-light-green rounded-t-lg': currentTab === option.value,
            'text-gray-500 border-b-4 border-medium-green cursor-pointer':
              currentTab !== option.value,
          })}
          onClick={() => onChange(option.value)}
        >
          {option.label}
        </div>
      ))}
    </div>
  );
};

export default Tabs;
