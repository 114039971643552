import React from 'react';

import Button from 'components/Button';
import Toggle from 'components/Toggle';

const Discounts = () => {
  return (
    <div className="p-8 h-full">
      <div className="flex items-center justify-between">
        <p className="text-2xl font-semibold">Discounts</p>
        <Button rounded={false}>Add Discount</Button>
      </div>
      <div className="flex flex-col gap-y-2 mt-4">
        <div className="flex flex-row items-center">
          <div className="basis-1/3 font-semibold">Discount name</div>
          <div className="basis-1/4 font-semibold">Period</div>
          <div className="basis-1/4 font-semibold">Discount</div>
          <div className="basis-1/6 font-semibold text-center">Action</div>
        </div>
        <div className="flex flex-row items-center py-2 px-3 bg-white rounded-xl">
          <div className="basis-1/3">“Mother’s day” discount</div>
          <div className="basis-1/4">22/10/2023 - 24/10/2023</div>
          <div className="basis-1/4 text-main-green font-bold">-50%</div>
          <div className="basis-1/6 flex justify-center">
            <Toggle defaultChecked />
          </div>
        </div>
        <div className="flex flex-row items-center py-2 px-3 bg-white rounded-xl">
          <div className="basis-1/3">“Help teachers” discount</div>
          <div className="basis-1/4">08/03/2023</div>
          <div className="basis-1/4 text-main-green font-bold">-25%</div>
          <div className="basis-1/6 flex justify-center">
            <Toggle />
          </div>
        </div>
        <div className="flex flex-row items-center py-2 px-3 bg-white rounded-xl">
          <div className="basis-1/3">“Ramadan” discount</div>
          <div className="basis-1/4">16/02/2023 - 16/03/2023</div>
          <div className="basis-1/4 text-main-green font-bold">-30%</div>
          <div className="basis-1/6 flex justify-center">
            <Toggle />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Discounts;
