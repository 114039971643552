import React, { useEffect, useMemo, useState } from 'react';

import { useAppSelector } from 'hooks/store';
import { selectSyncMap } from 'store/status.slice';

const StatusIndicator = ({ labelView = false, size = 'sm', user }) => {
  const syncMap = useAppSelector(selectSyncMap);
  const [status, setStatus] = useState('offline');

  const checkStatus = (isRemoved, args) => {
    if (user.name === args?.item?.data?.name && !isRemoved) {
      setStatus(args?.item?.data?.status);
    }
    if (user.name === args?.previousItemData?.name && isRemoved) {
      setStatus('offline');
    }
  };

  const indicatorClassName = useMemo(() => {
    let className = 'block rounded-full';
    switch (size) {
      case 'lg':
        className = `${className} w-6 h-6`;
        break;
      default:
        className = `${className} w-3 h-3`;
        break;
    };
    switch (status) {
      case 'online':
        className = `${className} bg-green-600`;
        break;
      case 'away':
        className = `${className} bg-amber-300`;
        break;
      case 'busy':
        className = `${className} bg-red-500`;
        break;
      case 'offline':
        className = `${className} bg-white border border-gray-800`;
        break;
      default:
        break;
    };
    return className;
  }, [size, status]);

  useEffect(() => {
    if (syncMap && user) {
      syncMap
        .get(String(user.id))
        .then((res) => setStatus(res?.data?.status))
        .catch(() => setStatus('offline'));

      syncMap.on('itemAdded', (args) => checkStatus(false, args));
      syncMap.on('itemUpdated', (args) => checkStatus(false, args));
      syncMap.on('itemRemoved', (args) => checkStatus(true, args));
    }
  }, [syncMap]);

  if (labelView) {
    return <span className="capitalize">{status}</span>;
  }

  return (
    <span
      className={indicatorClassName}
      title={status.charAt(0).toUpperCase() + status.slice(1)}
    />
  );
};

export default StatusIndicator;
