import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { restApi } from 'api/rest.api';
import { authSlice } from './auth.slice';
import { dataSlice } from './data.slice';
import { statusSlice } from './status.slice';

const persistConfig = {
  key: 'root',
  storage,
};
const persistedAuthReducer = persistReducer(persistConfig, authSlice.reducer);

export const store = configureStore({
  reducer: {
    [restApi.reducerPath]: restApi.reducer,
    auth: persistedAuthReducer,
    data: dataSlice.reducer,
    status: statusSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(restApi.middleware),
});

export const persistor = persistStore(store);
