import React, { useEffect, useState } from 'react';

import { useAppSelector } from 'hooks/store';
import { selectProviderTypes } from 'store/data.slice';
import BookingItem from 'components/BookingItem';
import InfiniteScrollingContainer from 'components/InfiniteScrollingContainer';
import { useFetchBookingsMutation } from 'api/rest.api';
import { BookingStatus } from 'utils/labels';

const CustomerDashboard = () => {
  const providerTypes = useAppSelector(selectProviderTypes) || [];
  const [bookings, setBookings] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const [fetchBookings] = useFetchBookingsMutation();

  const getBookings = async () => {
    const data = {
      skip: bookings.length,
      status: BookingStatus.ACCEPTED,
    };
    await fetchBookings(data)
      .unwrap()
      .then((res) => {
        if (res.success) {
          setBookings([...bookings, ...res.bookings]);
          if (res.bookings.length === 0 || res.bookings.length < 30) {
            setHasMore(false);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getBookings();
    console.log(providerTypes);
  }, []);

  return (
    <div className="flex-1 flex flex-col pt-6 overflow-y-hidden">
      <div className="relative max-w-[calc(100vw_-_300px)]">
        {/* carousel view of provider types */}
      </div>
      {/* upcoming appointments */}
      <div className="flex-1 flex flex-col pt-8 overflow-y-hidden">
        <p className="ml-2 text-2xl font-semibold">Upcoming Appointments</p>
        <div
          id="scrollableDiv"
          className="flex-1 mt-4 overflow-y-auto"
        >
          <InfiniteScrollingContainer
            dataLength={bookings.length}
            onNext={getBookings}
            hasMore={hasMore}
            endMessage={
              bookings.length === 0 ? 'No appointments available' : 'No appointments anymore'
            }
            scrollableTarget="scrollableDiv"
          >
            <div className="grid grid-cols-3 gap-4">
              {bookings.map((booking) => (
                <BookingItem
                  key={`cp-appointment-${booking.id}`}
                  booking={booking}
                />
              ))}
            </div>
          </InfiniteScrollingContainer>
        </div>
      </div>
    </div>
  );
};

export default CustomerDashboard;
