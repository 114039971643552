import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SyncClient } from 'twilio-sync';

const initialState = {
  token: '',
  syncClient: null,
  syncMap: null,
};

export const syncConnect = createAsyncThunk('syncConnect', async (data) => {
  const { user, token } = data;
  const client = new SyncClient(token);
  // eslint-disable-next-line no-undef
  const map = await client.map(process.env.REACT_APP_Twilio_SYNC_Map);
  try {
    const currentUserSync = await map.get(String(user.id));
    if (currentUserSync) {
      await map.update(String(user.id), { name: user.name, status: 'online' });
    }
  } catch (error) {
    if (error.status === 404 && error.code === 54201) {
      await map.set(String(user.id), { name: user.name, status: 'online' });
    }
    console.log(error);
  }

  client.on('connectionStateChanged', (newState) => {
    if (newState === 'disconnected') {
      const url = `${window.location.origin}/login`;
      window.location.href = url;
    }
  });

  return {
    token,
    client,
    map,
  };
});

export const syncDisconnect = createAsyncThunk('syncDisconnect', async (data) => {
  const { user, syncMap } = data;
  try {
    if (syncMap) {
      await syncMap.remove(String(user.id));
    }
  } catch (error) {
    console.log(error);
  }
});

export const updateStatus = createAsyncThunk('updateStatus', async (data) => {
  const { user, status, syncMap } = data;
  try {
    if (syncMap) {
      await syncMap.update(String(user.id), { name: user.name, status });
    }
  } catch (err) {
    console.log(err);
  }
});

export const statusSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(syncConnect.fulfilled, (state, action) => {
      state.token = action.payload.token;
      state.syncClient = action.payload.client;
      state.syncMap = action.payload.map;
    });
    builder.addCase(syncDisconnect.fulfilled, (state) => {
      state.token = '';
      state.syncClient = null;
      state.syncMap = null;
    });
  },
});

export default statusSlice.reducer;

export const selectSyncMap = (state) => state.status.syncMap;
export const selectSyncToken = (state) => state.status.token;
