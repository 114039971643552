import React, { createRef, useMemo, useRef } from 'react';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';

import Avatar from 'components/Avatar';
import Input from 'components/Input';
import Button from 'components/Button';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { selectProviderTypes } from 'store/data.slice';
import { useUpdateUserMutation, useUploadAvatarMutation } from 'api/rest.api';

import { ReactComponent as UserIcon } from 'assets/icons/user.svg';
import { ReactComponent as MailIcon } from 'assets/icons/envelope.svg';
import { ReactComponent as PhoneCallIcon } from 'assets/icons/phone_call.svg';
import { ReactComponent as FileCheckIcon } from 'assets/icons/file_check.svg';
import { ReactComponent as PencilIcon } from 'assets/icons/pencil.svg';
import { updateUser } from 'store/auth.slice';

const Profile = ({ user }) => {
  const dispatch = useAppDispatch();
  const providerTypes = useAppSelector(selectProviderTypes) || [];
  const inputRef = useRef(null);
  const dropzoneRef = createRef();

  const [updateUserProfile, { isLoading }] = useUpdateUserMutation();
  const [uploadAvatar] = useUploadAvatarMutation();

  const handleChangeAvatarImage = (file) => {
    const formData = new FormData();
    formData.append('attachments', file);
    formData.append('type', 'avatar');
    uploadAvatar({ id: user.id, data: formData })
      .unwrap()
      .then((res) => {
        if (res.success) {
          dispatch(updateUser({ ...user, image: res.image }));
          toast('Your avatar image has been updated successfully', { type: 'success' });
        }
      })
      .catch((err) => console.log(err));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    const payload = {
      id: user.id,
      name: data.get('name'),
      email: data.get('email'),
      phone: data.get('phone') ? `+1${data.get('phone').replace(/[^A-Z0-9]+/ig, '')}` : '',
      landline: data.get('landline') ? data.get('landline').replace(/[^A-Z0-9]+/ig, '') : '',
      fax: data.get('fax') ? data.get('fax').replace(/[^A-Z0-9]+/ig, '') : '',
      about: data.get('bio') || '',
    };
    if (!payload.name || !payload.email || !payload.phone) {
      return toast('Please add mandatory fields', { type: 'error' });
    }
    updateUserProfile(payload)
      .unwrap()
      .then((res) => {
        if (res.success) {
          dispatch(updateUser({ ...user, ...payload }));
        }
        toast('Your profile has been updated successfully', { type: 'success' });
      })
      .catch((err) => console.log(err));
  };

  const providerTypeName = useMemo(() => {
    const types = user.type ? String(user.type).split('').map(Number) : [];
    const typeNames = types.map((tp) => providerTypes.find((pt) => pt.id === tp)?.name);
    if (types.length) {
      return typeNames.join(', ');
    }
    return '';
  }, [user]);

  return (
    <div className="grid grid-cols-2 gap-4 p-8 h-full">
      <div className="flex flex-col">
        <p className="text-2xl font-semibold">User information</p>
        <form
          className="flex-1 flex flex-col gap-y-3 mt-5 w-full max-w-lg"
          onSubmit={handleSubmit}
        >
          <div className="flex-1 flex flex-col gap-y-3 text-base">
            <Input
              name="name"
              type="text"
              defaultValue={user.name}
              placeholder="*name"
              Icon={({ className }) => <UserIcon className={`${className} mx-1`} />}
            />
            <Input
              name="email"
              type="email"
              defaultValue={user.email || ''}
              placeholder="*Email address"
              Icon={({ className }) => <MailIcon className={`${className} mx-1`} />}
            />
            <Input
              name="phone"
              type="phone"
              defaultValue={user.phone || ''}
              placeholder="*Tel number"
              Icon={({ className }) => <PhoneCallIcon className={`${className} mx-1`} />}
            />
            <Input
              name="landline"
              type="phone"
              defaultValue={`+1${user.landline}` || ''}
              placeholder="Landline number"
              Icon={({ className }) => <PhoneCallIcon className={`${className} mx-1`} />}
            />
            <Input
              name="fax"
              type="phone"
              defaultValue={`+1${user.fax}` || ''}
              placeholder="Fax number"
              Icon={({ className }) => <PhoneCallIcon className={`${className} mx-1`} />}
            />
            {/* <fieldset
              id="phoneType"
              className="flex items-center gap-x-6 mb-1"
            >
              <div className="flex items-center gap-x-1">
                <input
                  id="mobile"
                  name="phoneType"
                  type="radio"
                  value="mobile"
                  defaultChecked
                  className="w-4 h-4 text-main-green bg-gray-100 border-gray-300 accent-main-green"
                />
                <label htmlFor="mobile">Mobile</label>
              </div>
              <div className="flex items-center gap-x-1">
                <input
                  id="landline"
                  name="phoneType"
                  type="radio"
                  value="landline"
                  className="w-4 h-4 text-main-green bg-gray-100 border-gray-300 accent-main-green"
                />
                <label htmlFor="landline">Landline</label>
              </div>
              <div className="flex items-center gap-x-1">
                <input
                  id="fax"
                  name="phoneType"
                  type="radio"
                  value="fax"
                  className="w-4 h-4 text-main-green bg-gray-100 border-gray-300 accent-main-green"
                />
                <label htmlFor="fax">Fax</label>
              </div>
            </fieldset> */}
            <Input
              name="bio"
              multi
              placeholder="About you..."
              rows={5}
              defaultValue={user.about || ''}
              Icon={({ className }) => <UserIcon className={`${className} mx-1`} />}
            />
            <Button
              cssClass="mt-3"
              color="dark"
              variant="outlined"
            >
              Add emergency contact
            </Button>
          </div>
          <div className="flex items-center gap-x-3">
            <Button
              cssClass="w-44"
              type="submit"
              loading={isLoading}
              disabled={isLoading}
            >
              Save changes
            </Button>
            <Button
              cssClass="w-44"
              color="danger"
            >
              Close account
            </Button>
          </div>
        </form>
      </div>
      <div>
        <p className="text-2xl font-semibold">Profile photo</p>
        <div className="flex items-center gap-x-8 mt-5">
          <div
            className="relative cursor-pointer"
            onClick={() => inputRef.current.click()}
          >
            <div className="absolute bottom-3 right-1 p-2 bg-white border border-[#D2D2D2] rounded-full z-10">
              <PencilIcon />
            </div>
            <Avatar
              hasIndicator={false}
              user={user}
              size="lg"
            />
            <input
              ref={inputRef}
              type="file"
              id="myfile"
              name="myfile"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={(e) => {
                if (e.target.files) {
                  handleChangeAvatarImage(e.target.files[0]);
                }
              }}
            />
          </div>
          <div>
            <p className="text-2xl font-semibold">{user.name}</p>
            <p className="text-lg text-gray-500">{providerTypeName}</p>
          </div>
        </div>
        <Dropzone
          ref={dropzoneRef}
          multiple={false}
          onDrop={(files, errors) => {
            if (errors?.length > 0) {
              toast('Please upload PDF file of license.', { type: 'error' });
            }
            if (files.length) {
              // TODO
              console.log(files);
            }
          }}
          accept={{
            'application/pdf': ['.pdf'],
          }}
          disabled={false}
        >
          {({ getRootProps }) => (
            <section>
              <div
                {...getRootProps()}
                className="flex flex-col items-center gap-y-4 mt-6 p-8 bg-white border border-dashed border-[#D2D2D2] rounded-xl"
              >
                <FileCheckIcon />
                <p className="text-gray-500">Upload license</p>
              </div>
            </section>
          )}
        </Dropzone>
        <Dropzone
          ref={dropzoneRef}
          multiple={false}
          onDrop={(files, errors) => {
            if (errors?.length > 0) {
              toast('Please upload image file of ID.', { type: 'error' });
            }
            if (files.length) {
              // TODO
              console.log(files);
            }
          }}
          accept={{
            'image/*': ['.jpeg', '.png'],
          }}
          disabled={false}
        >
          {({ getRootProps }) => (
            <section>
              <div
                {...getRootProps()}
                className="flex flex-col items-center gap-y-4 mt-2 p-8 bg-white border border-dashed border-[#D2D2D2] rounded-xl"
              >
                <FileCheckIcon />
                <p className="flex flex-col items-center leading-4 text-gray-500">
                  <span>Upload ID</span>
                  <span>(Driver’s license / Passport)</span>
                </p>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
    </div>
  );
};

export default Profile;
