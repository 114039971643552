import React from 'react';
import { HiOutlineMail } from 'react-icons/hi';

import Button from 'components/Button';
import Input from 'components/Input';

import { ReactComponent as GooglePlayIcon } from 'assets/icons/google_play.svg';
import { ReactComponent as AppStoreIcon } from 'assets/icons/app_store.svg';
import PhoneCallImg from 'assets/doctor_call.png';

const FifthSection = () => {
  return (
    <div className="mx-auto pt-10 pb-20 w-full max-w-8xl">
      <div className="p-10 bg-light-green rounded-2xl">
        <div className="flex items-center gap-x-10">
          <div className="w-1/2">
            <p className="text-3xl font-medium">Download the CarePlatform app</p>
            <p className="mt-2 text-gray-500 leading-5">
              Access video consultation with CarePlatform’s top Providers on this app. Connect with
              Providers online, available 24/7, from the comfort of your home.
            </p>
            <p className="mt-5">Get the link to download the app</p>
            <div className="flex items-center gap-x-2 mt-2">
              <div className="max-w-xs w-full">
                <Input
                  rounded
                  withCountryCode
                  type="phone"
                  placeholder="Enter phone number"
                />
              </div>
              <Button>Send SMS</Button>
            </div>
            <div className="flex items-center gap-x-2 mt-10">
              <div className="py-4 px-8 bg-white rounded-full cursor-pointer">
                <GooglePlayIcon />
              </div>
              <div className="py-4 px-8 bg-white rounded-full cursor-pointer">
                <AppStoreIcon />
              </div>
            </div>
          </div>
          <div className="flex-1">
            <img
              className="max-w-sm"
              src={PhoneCallImg}
            />
          </div>
        </div>
        <div className="pt-8">
          <p className="text-gray-500">Join our newsletter</p>
          <div className="flex items-center gap-x-10 ">
            <div className="w-1/2">
              <p className="mt-2 text-2xl font-medium leading-6">
                Receive health & wellness updates and tips right to your inbox
              </p>
            </div>
            <form className="flex-1 flex items-center gap-x-2">
              <div className="flex-1">
                <Input
                  rounded
                  type="email"
                  placeholder="Enter your email"
                  Icon={(props) => (
                    <div
                      {...props}
                      className={`${props.className} -ml-2 p-3 bg-light-green rounded-full`}
                    >
                      <HiOutlineMail className="text-lg text-main-green" />
                    </div>
                  )}
                />
              </div>
              <Button type="submit">Subscribe</Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FifthSection;
