import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import clsx from 'clsx';

import { useAppSelector } from 'hooks/store';
import { selectProviderTypes } from 'store/data.slice';
import Button from 'components/Button';
import { getCDNImage } from 'utils/common';

const SecondSection = () => {
  const providerTypes = useAppSelector(selectProviderTypes);
  const [isFirst, setIsFirst] = useState(true);
  const [isLast, setIsLast] = useState(false);

  const sliderRef = useRef(null);

  const sliderSettings = {
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 3,
    afterChange: (index) => {
      if (index === 0) {
        setIsFirst(true);
        setIsLast(false);
        // 4 here means sliderToScroll + 1
      } else if (index === providerTypes.length - 4) {
        setIsFirst(false);
        setIsLast(true);
      } else {
        setIsFirst(false);
        setIsLast(false);
      }
    },
  };

  return (
    <div className="mx-auto pb-16 p-4 w-full max-w-8xl">
      <div className="flex items-center justify-between">
        <div className="pt-16 pb-10 w-full border-t">
          <p className="text-3xl font-semibold">Find 25+ specialties for your care</p>
          <p className="mt-2 w-[500px] leading-4">
            Access a wide variety of healthcare specialities and consult with top specialists
          </p>
        </div>
        {providerTypes.length > 4 ? (
          <div className="flex gap-x-3">
            <div
              className={clsx(
                'p-1.5 rounded-full border border-main-green hover:bg-[#CFF0EB] cursor-pointer',
                { 'pointer-events-none opacity-40': isFirst },
              )}
              onClick={() => sliderRef.current.slickPrev()}
            >
              <BsArrowLeftShort className="text-4xl text-main-green" />
            </div>
            <div
              className={clsx(
                'p-1.5 rounded-full border border-main-green hover:bg-[#CFF0EB] cursor-pointer',
                { 'pointer-events-none opacity-40': isLast },
              )}
              onClick={() => sliderRef.current.slickNext()}
            >
              <BsArrowRightShort className="text-4xl text-main-green" />
            </div>
          </div>
        ) : null}
      </div>
      <div className="max-w-[calc(100vw_-_76px)]">
        <Slider
          {...sliderSettings}
          ref={sliderRef}
        >
          {providerTypes.map((pType, index) => (
            <div
              key={`provier-type-${index}`}
              className="px-3"
            >
              <div
                className={clsx('rounded-xl', {
                  'bg-[#E2F4F1]': index % 3 === 0,
                  'bg-[#F6EAFF]': index % 3 === 1,
                  'bg-[#E4F1FF]': index % 3 === 2,
                })}
              >
                <img
                  src={getCDNImage(pType.image)}
                  className="aspect-square rounded-xl"
                />
                <div className="flex flex-col gap-y-4 mt-10 p-5">
                  <p className="text-xl font-bold">{pType.name}</p>
                  <Button>Book Now</Button>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default SecondSection;
