import React, { useState } from 'react';
import clsx from 'clsx';

import Profile from './Profile';
import Language from './Language';
import Services from './Services';
import Locations from './Locations';
import Notification from './Notification';
import Security from './Security';
import Credits from './Credits';
import Pharmacies from './Pharmacies';
import Discounts from './Discounts';
import Packages from './Packages';
import Subscription from './Subscription';
import Media from './Media';

const Settings = ({ user }) => {
  const [tab, setTab] = useState('profile');

  return (
    <div
      id="cp-settings-page"
      className="flex h-full pt-[90px] pb-5"
    >
      <div className="flex-1 flex flex-col mt-6 mx-auto w-full max-w-8xl">
        <div className="flex items-center">
          <div
            className={clsx('py-3 px-5 cursor-pointer', {
              'text-[#6B6B6B]': tab !== 'detail',
              'text-black font-semibold bg-[#F8F8F8] rounded-t-xl': tab === 'profile',
            })}
            onClick={() => setTab('profile')}
          >
            Profile
          </div>
          <div
            className={clsx('py-3 px-5 cursor-pointer', {
              'text-[#6B6B6B]': tab !== 'review',
              'text-black font-semibold bg-[#F8F8F8] rounded-t-xl': tab === 'language',
            })}
            onClick={() => setTab('language')}
          >
            Languages
          </div>
          <div
            className={clsx('py-3 px-5 cursor-pointer', {
              'text-[#6B6B6B]': tab !== 'consult',
              'text-black font-semibold bg-[#F8F8F8] rounded-t-xl': tab === 'service',
            })}
            onClick={() => setTab('service')}
          >
            Service
          </div>
          <div
            className={clsx('py-3 px-5 cursor-pointer', {
              'text-[#6B6B6B]': tab !== 'other',
              'text-black font-semibold bg-[#F8F8F8] rounded-t-xl': tab === 'location',
            })}
            onClick={() => setTab('location')}
          >
            Location
          </div>
          <div
            className={clsx('py-3 px-5 cursor-pointer', {
              'text-[#6B6B6B]': tab !== 'other',
              'text-black font-semibold bg-[#F8F8F8] rounded-t-xl': tab === 'notfication',
            })}
            onClick={() => setTab('notfication')}
          >
            Notifications
          </div>
          <div
            className={clsx('py-3 px-5 cursor-pointer', {
              'text-[#6B6B6B]': tab !== 'other',
              'text-black font-semibold bg-[#F8F8F8] rounded-t-xl': tab === 'security',
            })}
            onClick={() => setTab('security')}
          >
            Security
          </div>
          <div
            className={clsx('py-3 px-5 cursor-pointer', {
              'text-[#6B6B6B]': tab !== 'other',
              'text-black font-semibold bg-[#F8F8F8] rounded-t-xl': tab === 'credits',
            })}
            onClick={() => setTab('credits')}
          >
            Credits
          </div>
          <div
            className={clsx('py-3 px-5 cursor-pointer', {
              'text-[#6B6B6B]': tab !== 'other',
              'text-black font-semibold bg-[#F8F8F8] rounded-t-xl': tab === 'pharmacies',
            })}
            onClick={() => setTab('pharmacies')}
          >
            Pharmacies
          </div>
          <div
            className={clsx('py-3 px-5 cursor-pointer', {
              'text-[#6B6B6B]': tab !== 'other',
              'text-black font-semibold bg-[#F8F8F8] rounded-t-xl': tab === 'discounts',
            })}
            onClick={() => setTab('discounts')}
          >
            Discounts
          </div>
          <div
            className={clsx('py-3 px-5 cursor-pointer', {
              'text-[#6B6B6B]': tab !== 'other',
              'text-black font-semibold bg-[#F8F8F8] rounded-t-xl': tab === 'packages',
            })}
            onClick={() => setTab('packages')}
          >
            Packages
          </div>
          <div
            className={clsx('py-3 px-5 cursor-pointer', {
              'text-[#6B6B6B]': tab !== 'other',
              'text-black font-semibold bg-[#F8F8F8] rounded-t-xl': tab === 'subscription',
            })}
            onClick={() => setTab('subscription')}
          >
            Subscription
          </div>
          <div
            className={clsx('py-3 px-5 cursor-pointer', {
              'text-[#6B6B6B]': tab !== 'other',
              'text-black font-semibold bg-[#F8F8F8] rounded-t-xl': tab === 'media',
            })}
            onClick={() => setTab('media')}
          >
            Photos
          </div>
        </div>
        <div
          className={clsx('flex-1 bg-[#F8F8F8] rounded-b-xl', {
            'rounded-tr-xl': tab === 'profile',
            'rounded-t-xl': tab !== 'profile',
          })}
        >
          {tab === 'profile' ? <Profile user={user} /> : null}
          {tab === 'language' ? <Language user={user} /> : null}
          {tab === 'service' ? <Services user={user} /> : null}
          {tab === 'location' ? <Locations user={user} /> : null}
          {tab === 'notfication' ? <Notification user={user} /> : null}
          {tab === 'security' ? <Security user={user} /> : null}
          {tab === 'credits' ? <Credits user={user} /> : null}
          {tab === 'pharmacies' ? <Pharmacies user={user} /> : null}
          {tab === 'discounts' ? <Discounts user={user} /> : null}
          {tab === 'packages' ? <Packages user={user} /> : null}
          {tab === 'subscription' ? <Subscription user={user} /> : null}
          {tab === 'media' ? <Media user={user} /> : null}
        </div>
      </div>
    </div>
  );
};

export default Settings;
