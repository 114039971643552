import React from 'react';

import Button from 'components/Button';

const Subscription = () => {
  return (
    <div className="p-8 h-full">
      <p className="text-2xl font-semibold">Subscription</p>
      <div className="flex flex-col gap-y-4 mt-4">
        <div className="flex items-center justify-between gap-x-6 py-5 px-5 bg-white rounded-xl">
          <p>This is monthly subscription</p>
          <Button
            rounded={false}
            cssClass="text-sm"
          >
            Subscription
          </Button>
        </div>
        <div className="py-5 px-5 bg-white rounded-xl">
          <p className="text-lg font-medium">Payment method</p>
          <p className="mt-4 text-sm">Add a credit card</p>
          <div></div>
          <Button
            rounded={false}
            cssClass="mt-4 text-sm"
          >
            Add card
          </Button>
          <p className="mt-2 text-sm">
            Payment are handled with&nbsp;
            <span className="text-cyan-600 underline">Stripe</span>
          </p>
        </div>
        <div className="py-5 px-5 bg-white rounded-xl">
          <p className="text-lg font-medium">Transaction history</p>
          <div className="flex flex-col items-center justify-center h-40">
            <p className="text-xl font-semibold">Nothing found</p>
            <p className="text-sm text-[#6B6B6B]">
              You haven’t made a purchuase on Careplatform yet
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
