import React, { useMemo } from 'react';
import stc from 'string-to-color';

import { getCDNImage } from 'utils/common';
import StatusIndicator from './StatusIndicator';

import userImg from 'assets/person.jpg';

const Avatar = ({ user, size = 'sm', hasIndicator = true }) => {
  const avatarClassName = useMemo(() => {
    switch (size) {
      case 'md':
        return 'w-16 h-16 rounded-full';
      case 'lg':
        return 'w-48 h-48 rounded-full';
      default:
        return 'w-10 h-10 rounded-full';
    }
  }, [size]);

  const indicatorClassName = useMemo(() => {
    switch (size) {
      case 'md':
        return 'absolute right-1 bottom-0.5';
      case 'lg':
        return 'absolute right-5 bottom-3';
      default:
        return 'absolute right-0 bottom-0';
    }
  }, [size]);

  const nameChar = useMemo(() => {
    return user.lastName ? `${user.name[0]}${user.lastName[0]}` : `${user.name[0]}${user.name[1]}`;
  }, [user]);

  return (
    <div className="cp-avatar w-fit h-fit relative">
      {user.image ? (
        <img
          className={avatarClassName}
          src={user.image ? getCDNImage(user.image) : userImg}
        />
      ) : (
        <div
          className={`${avatarClassName} text-center uppercase flex items-center justify-center text-white font-semibold`}
          style={{ background: `${stc(nameChar)}` }}
        >
          <p>{nameChar}</p>
        </div>
      )}
      {hasIndicator ? (
        <div className={indicatorClassName}>
          <StatusIndicator
            user={user}
            size={size}
          />
        </div>
      ) : null}
    </div>
  );
};

export default Avatar;
