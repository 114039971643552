import React from 'react';

import Checkbox from 'components/Checkbox';
import Button from 'components/Button';
import Map from 'components/Map';

const Locations = ({ user }) => {
  const getAddress = (address) => {
    const arr = address.split(',');
    const address1 = [...arr].slice(0, arr.length - 3);
    const address2 = [...arr].slice(-3);
    return [address1.join(', '), address2.join(', ')];
  };

  return (
    <div className="p-8 h-full">
      <p className="text-2xl font-semibold">Location</p>
      <div className="mt-5 flex flex-col gap-y-2">
        {user.locations
          ? user.locations.map((location, index) => (
              <div
                key={`location-${index}`}
                className="grid grid-cols-2 gap-x-8"
              >
                <div className="flex flex-col justify-between py-3 px-5 h-[180px] bg-white rounded-xl">
                  <div className="flex flex-col gap-y-1">
                    <p className="text-xl font-medium">{getAddress(location.address)[0] || ''}</p>
                    <p>{getAddress(location.address)[1]}</p>
                  </div>
                  <div className="flex items-center justify-between">
                    <Checkbox
                      defaultChecked={index === 0}
                      label={index === 0 ? 'Default location' : 'Make default'}
                    />
                    <Button color="danger">Delete</Button>
                  </div>
                </div>
                <div>
                  <Map
                    rounded
                    center={{ lat: location.lat, lng: location.lng }}
                    markers={[
                      {
                        position: { lat: location.lat, lng: location.lng },
                        title: location.address,
                      },
                    ]}
                  />
                </div>
              </div>
            ))
          : null}
        <Button
          color="dark"
          variant="outlined"
        >
          Add location
        </Button>
      </div>
    </div>
  );
};

export default Locations;
