import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BsFillCheckCircleFill, BsFillStarFill } from 'react-icons/bs';
import moment from 'moment';

import Avatar from 'components/Avatar';
import Button from 'components/Button';
import { useAppSelector } from 'hooks/store';
import { selectAppointmentInfo } from 'store/data.slice';
import { AppointmentType } from 'utils/labels';

const AppointmentConfirmation = ({ user }) => {
  const navigate = useNavigate();
  const appointmentInfo = useAppSelector(selectAppointmentInfo) || null;

  const getAppointmentType = (type) => {
    switch (type) {
      case AppointmentType.VIRTUAL:
        return 'Virtual visit';
      case AppointmentType.PROVIDER_LOCATION:
        return 'Clinic visit';
      case AppointmentType.CUSTOMER_LOCATION:
        return 'Mobile visit';
      default:
        return '';
    }
  };

  useEffect(() => {
    if (user) {
      if (!appointmentInfo.slot) {
        navigate('/dashboard');
      }
    }
  }, []);

  return (
    <div className="mx-auto pt-[90px] w-full max-w-8xl">
      {appointmentInfo.slot ? (
        <div className="grid grid-cols-2 gap-10 mt-10 py-8 px-10 bg-[#F8F8F8] rounded-xl">
          <div className="flex items-center gap-x-6">
            <Avatar
              user={appointmentInfo.provider}
              size="lg"
            />
            <div className="flex-1 flex items-start justify-between gap-x-6">
              <div className="flex-1">
                <p className="text-2xl font-semibold">
                  {`${appointmentInfo.provider.name} ${appointmentInfo.provider.lastName || ''}`}
                </p>
                <div className="flex items-center gap-x-2">
                  <BsFillCheckCircleFill color="#08A284" />
                  Medical registration verified!
                </div>
                <div className="flex items-center gap-x-2">
                  <BsFillStarFill color="#08A284" />
                  99% (249 patience)
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="grid grid-cols-2 gap-3 text-base">
              <div className="flex items-center gap-x-2">
                <p className="text-gray-500">Type:</p>
                <p>{getAppointmentType(appointmentInfo.type)}</p>
              </div>
              <div className="flex items-center gap-x-2">
                <p className="text-gray-500">Date:</p>
                <p>
                  {`${moment(appointmentInfo.date, 'YYYY-MM-DD').format('MMMM Do YYYY')}, ${moment({
                    hour: Number(appointmentInfo.slot.slot) / 4,
                    minute: 15 * (Number(appointmentInfo.slot.slot) % 4),
                  }).format('hh:mm a')}`}
                </p>
              </div>
              <div className="flex items-center gap-x-2">
                <p className="text-gray-500">Service:</p>
                <p>{appointmentInfo.service.name}</p>
              </div>
              <div className="flex items-center gap-x-2">
                <p className="text-gray-500">Duration:</p>
                <p>{`${appointmentInfo.item.duration} mins`}</p>
              </div>
              <div className="flex items-center gap-x-2">
                <p className="text-gray-500">Price:</p>
                <p>{`$${appointmentInfo.item.price.toFixed(2)}`}</p>
              </div>
              <div className="flex items-center gap-x-2">
                <p className="text-gray-500">Total Price:</p>
                <p>{`$${appointmentInfo.item.price.toFixed(2)}`}</p>
              </div>
            </div>
            {appointmentInfo.type !== AppointmentType.VIRTUAL ? (
              <div className="flex items-center gap-x-2 mt-3">
                <p className="text-gray-500">Location:</p>
                <p>{appointmentInfo.location.label}</p>
              </div>
            ) : null}
            <Button cssClass="mt-5">Book appointment</Button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default AppointmentConfirmation;
