import React from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';

import Button from 'components/Button';
import Input from 'components/Input';

const PackageItem = () => {
  return (
    <div className="flex items-start justify-between p-5 bg-white rounded-xl">
      <div className="flex flex-col gap-y-1">
        <span className="py-0.5 px-2 w-fit text-xs text-white bg-main-green rounded-full">active</span>
        <p>Package Name</p>
      </div>
      <div>
        <BsThreeDotsVertical />
      </div>
    </div>
  );
};

const Packages = () => {
  return (
    <div className="p-8 h-full">
      <p className="text-2xl font-semibold">Packages</p>
      <div className="grid grid-cols-2 gap-10 mt-4">
        <div className="py-5 px-5 bg-white rounded-xl">
          <p className="text-xl font-semibold">Add new package</p>
          <div className="flex flex-col gap-y-4 mt-4">
            <div className="flex flex-col gap-y-1">
              <p>Name</p>
              <Input
                type="text"
                placeholder="Package name"
              />
            </div>
            <div className="flex flex-col gap-y-1">
              <p>Price</p>
              <Input
                type="number"
                placeholder="0.00"
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="flex flex-col gap-y-1">
                <p>Expiration date</p>
                <Input
                  type="text"
                  placeholder="Package name"
                />
              </div>
              <div className="flex flex-col gap-y-1">
                <p>Available for purchase</p>
                <Input
                  type="number"
                  placeholder="0"
                />
              </div>
            </div>
            <div className="flex items-center justify-end gap-x-4">
              <Button
                cssClass="text-sm"
                rounded={false}
                color="dark"
                variant="outlined"
              >
                Add appointment
              </Button>
              <Button
                cssClass="text-sm"
                rounded={false}
              >
                Add package
              </Button>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-y-2">
          <PackageItem />
          <PackageItem />
          <PackageItem />
          <PackageItem />
        </div>
      </div>
    </div>
  );
};

export default Packages;
