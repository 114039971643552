import React from 'react';

import Select from 'components/Select';
import Toggle from 'components/Toggle';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { selectLanguages } from 'store/data.slice';
import {
  useAddLanguageMatchMutation,
  useDeleteLanguageMatchMutation,
  useUpdateUserMutation,
} from 'api/rest.api';
import { updateUser } from 'store/auth.slice';

const Language = ({ user }) => {
  const dispatch = useAppDispatch();
  const languages = useAppSelector(selectLanguages) || [];

  const [updateuserProfile] = useUpdateUserMutation();
  const [addLanguageMatch] = useAddLanguageMatchMutation();
  const [deleteLanguageMatch] = useDeleteLanguageMatchMutation();

  const handleChangeLanguage = (type, value) => {
    updateuserProfile({ id: user.id, [type]: value })
      .unwrap()
      .then((res) => {
        if (res.success) {
          dispatch(updateUser({ ...user, [type]: value }));
        }
      })
      .catch((err) => console.log(err));
  };

  const handleToggleConsultLanguage = (value, id) => {
    if (value) {
      addLanguageMatch({ providerId: user.id, languageId: id })
        .unwrap()
        .then((res) => {
          if (res.success) {
            const temp = languages.find((lang) => lang.id === id);
            dispatch(updateUser({ ...user, languages: [...user.languages, temp] }));
          }
        })
        .catch((err) => console.log(err));
    } else {
      deleteLanguageMatch(id)
        .unwrap()
        .then((res) => {
          if (res.success) {
            const temp = user.languages.filter((lang) => lang.id !== id);
            dispatch(updateUser({ ...user, languages: temp }));
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div className="p-8 h-full">
      <p className="text-2xl font-semibold">Language</p>
      <div className="mt-4 grid grid-cols-2 gap-4">
        <div className="flex flex-col gap-y-4 py-6 px-8 bg-white rounded-xl">
          <div className="flex flex-col gap-y-2">
            <p className="text-lg font-medium">Interface language</p>
            <Select
              value={
                user.interfaceLanguage
                  ? { value: user.interfaceLanguage, label: user.interfaceLanguage }
                  : null
              }
              options={languages.map((language) => ({
                value: language.name,
                label: language.name,
              }))}
              onChange={({ value }) => handleChangeLanguage('interfaceLanguage', value)}
            />
          </div>
          <div className="flex flex-col gap-y-2">
            <p className="text-lg font-medium">Communication language</p>
            <Select
              value={
                user.communicationLanguage
                  ? { value: user.communicationLanguage, label: user.communicationLanguage }
                  : null
              }
              options={languages.map((language) => ({
                value: language.name,
                label: language.name,
              }))}
              onChange={({ value }) => handleChangeLanguage('communicationLanguage', value)}
            />
          </div>
        </div>
        <div className="flex flex-col py-6 px-8 bg-white rounded-xl">
          <p className="text-lg font-medium">Consultation languages</p>
          <p className="mt-1 text-sm leading-4">
            Here you may choose the languages that you would like to communicate in during
            consultations
          </p>
          <div className="flex flex-col gap-y-3 mt-6">
            <div className="flex items-center justify-between">
              <p className="font-medium">Language</p>
              <p className="font-medium text-main-green">Active</p>
            </div>
            {languages.map((language, index) => (
              <div
                key={`language-${index}`}
                className="flex items-center justify-between"
              >
                <p className="font-medium">{language.name}</p>
                <Toggle
                  defaultChecked={!!user.languages.find((lang) => lang.name === language.name)}
                  onChange={(value) => handleToggleConsultLanguage(value, language.id)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Language;
