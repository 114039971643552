import React, { useState } from 'react';
import clsx from 'clsx';

const Toggle = ({ defaultChecked = false, labelMode = false, options, onChange }) => {
  const [checked, setChecked] = useState(defaultChecked);

  if (!labelMode) {
    return (
      <label className="relative inline-flex items-center cursor-pointer">
        <input
          type="checkbox"
          defaultChecked={defaultChecked}
          onChange={(e) => {
            onChange?.(e.target.checked);
            setChecked(e.target.checked);
          }}
          className="sr-only peer"
        />
        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-0 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-main-green"></div>
        {/* <span className="ml-3 text-sm font-medium text-gray-900">Toggle me</span> */}
      </label>
    );
  }

  return (
    <label className="flex cursor-pointer select-none items-center border border-main-gray rounded-full">
      <div className="relative">
        <input
          type="checkbox"
          className="sr-only"
          defaultChecked={defaultChecked}
          onChange={(e) => {
            onChange?.(e.target.checked);
            setChecked(e.target.checked);
          }}
        />
        <div className="box flex p-1 w-fit h-12 rounded-full">
          {options.map((option, index) => (
            <div
              key={`toggle-option-${index}`}
              className={clsx(
                'relative flex items-center w-1/2 py-1 px-5 text-sm text-center whitespace-nowrap z-10',
                {
                  'shadow-lg border border-main-gray rounded-full':
                    (index === 0 && !checked) || (index === 1 && checked),
                },
              )}
            >
              {option}
            </div>
          ))}
        </div>
        <div
          className={`absolute left-1 top-1 flex h-10 w-[calc(50%_-_4px)] items-center justify-center rounded-full bg-white transition ${
            checked ? 'translate-x-full' : ''
          }`}
        ></div>
      </div>
    </label>
  );
};

export default Toggle;
