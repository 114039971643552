import React from 'react';
import { BsExclamationCircle } from 'react-icons/bs';

import Input from 'components/Input';
import Button from 'components/Button';
import Toggle from 'components/Toggle';

const Security = () => {
  return (
    <div className="p-8 h-full">
      <p className="text-2xl font-semibold">Security</p>
      <div className="flex flex-col gap-y-4 mt-4">
        <div className="py-5 px-5 bg-white rounded-xl">
          <p className="text-lg font-medium">Change your password</p>
          <form className="flex flex-col gap-y-5 mt-4">
            <div className="grid grid-cols-2 gap-4">
              <Input
                name="oldPassword"
                placeholder="Old password"
              />
              <div className="flex items-start gap-x-2">
                <div className="py-0.5">
                  <BsExclamationCircle color="#08A284" />
                </div>
                <div className="text-sm">
                  <p>Can’t remember your password?</p>
                  <p>Please sign out and click on the forgot password link!</p>
                </div>
              </div>
              <Input
                name="newPassword"
                placeholder="New password"
              />
              <Input
                name="confirmPassword"
                placeholder="Confirm new password"
              />
            </div>
            <Button rounded={false}>Change Password</Button>
          </form>
        </div>
        <div className="py-5 px-5 bg-white rounded-xl">
          <p className="text-lg font-medium">Two-factor authentication</p>
          <div className="grid grid-cols-2 gap-6 mt-4">
            <div className="flex flex-col gap-y-3">
              <div className="flex items-center gap-x-2">
                <p>Two-factor authentication is currently</p>
                <p className="text-red-600 font-semibold">inactive</p>
              </div>
              <div className="w-fit">
                <Toggle />
              </div>
            </div>
            <div className="flex items-start gap-x-2">
              <div className="py-0.5">
                <BsExclamationCircle color="#08A284" />
              </div>
              <div className="text-sm">
                When you turn on two-factor authentication, we will send an extra security code to
                your mobile phone, every time you log in. You will need to enter this code to access
                your Careplatform account.
              </div>
            </div>
          </div>
        </div>
        <div className="py-5 px-5 bg-white rounded-xl">
          <p className="text-lg font-medium">Recent activity</p>
          <div className="flex flex-row-reverse gap-x-4 mt-4">
            <div className="basis-1/4 flex items-start gap-x-2">
              <div className="py-0.5">
                <BsExclamationCircle color="#08A284" />
              </div>
              <div className="flex flex-col gap-y-2 text-sm">
                <p>
                  If you don’t recognize any of these logins, please change your password and
                  <br />
                  <span className="text-cyan-600 underline">contact us.</span>
                </p>
                <p>
                  Want to get notified if there is suspicious activity on your account?
                  <br />
                  <span className="text-cyan-600 underline">Update your notification settings</span>
                </p>
              </div>
            </div>
            <div className="flex-1 flex flex-col gap-y-1">
              <div className="flex">
                <div className="basis-1/3 font-semibold text-main-green">Date</div>
                <div className="basis-1/3 font-semibold text-main-green">Ip Address</div>
                <div className="basis-1/3 font-semibold text-main-green">Device</div>
              </div>
              <div className="flex">
                <div className="basis-1/3">today at 10:29 AM</div>
                <div className="basis-1/3">195.158.30.67</div>
                <div className="basis-1/3">Chrome (Macintosh)</div>
              </div>
              <div className="flex">
                <div className="basis-1/3">last Saturday at 09:11 AM</div>
                <div className="basis-1/3">213.230.102.61</div>
                <div className="basis-1/3">Internet Explorer (WebKit)</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Security;
