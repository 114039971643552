import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ClipLoader } from 'react-spinners';

const InfiniteScrollingContainer = ({
  hasMore,
  scrollableTarget,
  dataLength,
  endMessage,
  onNext,
  children,
}) => {
  return (
    <InfiniteScroll
      dataLength={dataLength}
      next={onNext}
      hasMore={hasMore}
      loader={
        <div className="text-center">
          <ClipLoader
            color="#08A284"
            size={30}
          />
        </div>
      }
      endMessage={
        <p className="mt-2 text-base text-gray-600 text-center">
          <b>{endMessage}</b>
        </p>
      }
      scrollableTarget={scrollableTarget}
    >
      {children}
    </InfiniteScroll>
  );
};

export default InfiniteScrollingContainer;
