import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import Slider from 'react-slick';
import {
  BsChevronLeft,
  BsChevronRight,
  BsFillHeartFill,
  BsThreeDotsVertical,
} from 'react-icons/bs';
import { LuClock3 } from 'react-icons/lu';
import clsx from 'clsx';

import Button from './Button';
import StarRating from './StarRating';
import { useAppSelector } from 'hooks/store';
import { selectProviderTypes } from 'store/data.slice';

import sampleImg from 'assets/sample_provider.png';

const Actions = () => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <Popover
      isOpen={isPopoverOpen}
      positions={['bottom', 'top', 'left', 'right']}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowStyle={{ display: 'none' }}
        >
          <div className="py-3 bg-light-green rounded-lg">
            <div className="px-3">there?</div>
          </div>
        </ArrowContainer>
      )}
      onClickOutside={() => setIsPopoverOpen(false)}
    >
      <div
        className="p-2 hover:bg-gray-200 rounded-full"
        onClick={() => setIsPopoverOpen(true)}
      >
        <BsThreeDotsVertical className="text-xl" />
      </div>
    </Popover>
  );
};
const SliderPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} items-center justify-center h-full z-10`}
      style={{ ...style, display: 'flex', left: '8px' }}
    >
      <div
        className="relative p-1 text-sm text-main-dark bg-white border-main-gray rounded-full z-20 hover:text-main-dark hover:bg-gray-100"
        onClick={onClick}
      >
        <BsChevronLeft />
      </div>
    </div>
  );
};

const SliderNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} items-center justify-center h-full`}
      style={{ ...style, display: 'flex', right: '8px' }}
    >
      <div className="relative flex items-center h-full">
        <div
          className="relative p-1 text-sm text-main-dark bg-white rounded-full shadow-2xl z-20 hover:text-main-dark hover:bg-gray-100"
          onClick={onClick}
        >
          <BsChevronRight />
        </div>
      </div>
    </div>
  );
};

const sliderSettings = {
  dots: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <SliderNextArrow />,
  prevArrow: <SliderPrevArrow />,
  dotsClass: 'slick-dots bottom-2.5',
};

const UserItem = ({ hasActions = false, user }) => {
  const navigate = useNavigate();
  const providerTypes = useAppSelector(selectProviderTypes) || [];

  const providerTypeName = useMemo(() => {
    const types = user.type ? String(user.type).split('').map(Number) : [];
    const typeNames = types.map((tp) => providerTypes.find((pt) => pt.id === tp)?.name);
    if (types.length) {
      return typeNames.join(', ');
    }
    return '';
  }, [user]);

  return (
    <div className="relative bg-white">
      <div className="absolute top-2 right-2 flex items-center gap-x-2 cursor-pointer z-10">
        {user.isFavorite !== undefined ? (
          <div>
            <BsFillHeartFill
              className={clsx('text-2xl', {
                'text-red-600': user.isFavorite,
                'text-gray-300': !user.isFavorite,
              })}
            />
          </div>
        ) : null}
        {hasActions ? <Actions /> : null}
      </div>
      <div>
        <Slider {...sliderSettings}>
          <div className="flex items-center justify-center aspect-square">
            <img src={sampleImg} />
          </div>
          <div className="flex items-center justify-center aspect-square">
            <img src={sampleImg} />
          </div>
          <div className="flex items-center justify-center aspect-square">
            <img src={sampleImg} />
          </div>
          <div className="flex items-center justify-center aspect-square">
            <img src={sampleImg} />
          </div>
        </Slider>
      </div>
      <div className="flex flex-col gap-y-2 py-4">
        <div className="flex items-center justify-between">
          <p className="mt-0 text-lg font-semibold">{`${user.name} ${user.lastName || ''}`}</p>
          <p className="text text-[#6B6B6B] font-medium">{providerTypeName}</p>
        </div>
        <p className="text-sm text-[#6B6B6B]">
          The certificated audiologist that helps you with your ear problems
        </p>
        <div className="flex items-center gap-x-2 mt-2 text-sm text-[#6B6B6B]">
          <LuClock3 />
          Mon - Fri / 7:00 AM - 5:00 PM
        </div>
        <div className="flex items-center gap-x-1.5">
          <p className="font-semibold">$36.00</p>
          <p className="text-sm">/ appointment</p>
        </div>
        <div className="flex items-center justify-between">
          <Button onClick={() => navigate(`/providers/${user.id}`)}>Book Now</Button>
          <StarRating value={user.rating || 0} />
        </div>
      </div>
    </div>
  );
};

export default UserItem;
