import React from 'react';
import { BsExclamationCircle } from 'react-icons/bs';

import Select from 'components/Select';
import Map from 'components/Map';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';

const Pharmacies = () => {
  return (
    <div className="p-8 h-full">
      <p className="text-2xl font-semibold">Pharmacies</p>
      <div className="flex flex-col gap-y-4 mt-4">
        <div className="grid grid-cols-2 gap-10 py-5 px-5 bg-white rounded-xl">
          <div className="flex flex-col">
            <p className="text-lg font-medium">Map view</p>
            <div className="flex-1 mt-3">
              <Map
                rounded
                center={{ lat: 34.0584, lng: -118.278 }}
                markers={[
                  {
                    position: { lat: 34.0584, lng: -118.278 },
                    title: 'Best Pharmacy',
                  },
                ]}
              />
            </div>
          </div>
          <div className="flex flex-col">
            <p className="mb-2 text-lg font-medium">Search pharmacy</p>
            <Select
              options={[{ value: 1, label: 'Health Connect Pharmacy' }]}
              placeholder="Search pharmacy..."
            />
            <div className="flex items-start gap-x-2 mt-32">
              <div className="py-0.5">
                <BsExclamationCircle color="#08A284" />
              </div>
              <div className="text-sm">
                Careplatform will send your prescription to any pharmacy you choose
              </div>
            </div>
          </div>
        </div>
        <Button rounded={false}>Add Pharmacy</Button>
        <div className="mt-6">
          <p className="text-lg font-medium">Your pharmacies</p>
          <div className="grid grid-cols-2 gap-8 mt-4">
            <div className="flex flex-col gap-y-4 p-5 bg-white rounded-xl">
              <p className="text-xl font-medium">Health Connect Pharmacy</p>
              <div className="text-sm">
                <p>10 Desbrisay Ave #2</p>
                <p>Moncton, New Brunswick, E1E 0G8</p>
                <p>(506) 867-9854</p>
              </div>
              <div className="flex items-center justify-between">
                <Checkbox label="Make default" />
                <Button color="danger">Delete</Button>
              </div>
            </div>
            <div className="flex flex-col gap-y-4 p-5 bg-white rounded-xl">
              <p className="text-xl font-medium">Shoppers Drug Mart Moncton Medical Clinic</p>
              <div className="text-sm">
                <p>10 Desbrisay Ave #2</p>
                <p>Moncton, New Brunswick, E1E 0G8</p>
                <p>(506) 867-9854</p>
              </div>
              <div className="flex items-center justify-between">
                <Checkbox
                  defaultChecked
                  label="Default location"
                />
                <Button color="danger">Delete</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pharmacies;
