import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import OtpInput from 'react-otp-input';
import { toast } from 'react-toastify';

import Button from 'components/Button';
import {
  useGetTermsAndConditionsMutation,
  useRequestVerificationCodeMutation,
  useUploadSignatureMutation,
  useVerfiyCodeMutation,
} from 'api/rest.api';
import Checkbox from 'components/Checkbox';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { selectCurrentUser, updateUser } from 'store/auth.slice';
import { UserRole } from 'utils/labels';

import logoImg from 'assets/logo.png';

const Verify = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectCurrentUser) || null;
  const [code, setCode] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);

  const sigCanvasRef = useRef(null);

  const [requestVerificationCode] = useRequestVerificationCodeMutation();
  const [verifyCode, { isLoading: isVerifying }] = useVerfiyCodeMutation();
  const [uploadSignature, { isLoading: isUploading }] = useUploadSignatureMutation();
  const [getTermsAndConditions] = useGetTermsAndConditionsMutation();

  const downloadTermsAndConditions = async () => {
    const { url } = await getTermsAndConditions().unwrap();
    window.open(url, '_blank');
  };

  const handleVerify = async () => {
    await verifyCode({ phone: user.phone, code })
      .unwrap()
      .then(() => setIsVerified(true))
      .catch((err) => {
        console.log(err);
        toast('Your code is incorrect.', {
          type: 'error',
        });
      });
  };

  const handleRequestCode = async () => {
    await requestVerificationCode()
      .unwrap()
      .catch((err) => {
        console.log(err);
        if (err.status === 429) {
          toast('Your resend reqests reached max limit. Please try again 30 minutes later.', {
            type: 'error',
          });
        } else {
          toast('Something went wrong. Please try again later.', {
            type: 'error',
          });
        }
      });
  };

  const handleSubmit = async () => {
    const signature = sigCanvasRef?.current?.toDataURL();
    const isEmpty = sigCanvasRef?.current?.isEmpty();
    if (isEmpty) {
      toast('Please add your signature.', {
        type: 'error',
      });
    } else {
      await uploadSignature({ signature })
        .unwrap()
        .then((res) => {
          if (res.success) {
            const toastMessage = user.signature
              ? 'Thank you for signing on Terms and Conditions.'
              : 'Your account has been activated.';
            toast(toastMessage, {
              type: 'success',
            });
            dispatch(updateUser({ ...user, status: 'active', signature: res.signature }));
            if (
              [UserRole.PROVIDER, UserRole.CLINIC_MEMBER].includes(user.role) &&
              user.onboardingStep !== 100
            ) {
              navigate('/onboarding');
            } else {
              navigate('/dashboard');
            }
          }
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (!isVerified) {
      handleRequestCode();
    }
  }, [isVerified]);

  return (
    <div className="flex w-full h-full">
      <div className="flex flex-col m-auto items-center w-full max-w-lg">
        <img
          src={logoImg}
          className="w-40 h-12"
        />
        {isVerified ? (
          <p className="mt-5 text-3xl font-semibold text-center">Welcome to Care Platform</p>
        ) : (
          <p className="mt-5 text-4xl font-semibold text-center">Verification</p>
        )}
        <p className="mt-5 text-base text-gray-500 text-center">
          {isVerified
            ? 'Please draw your signature'
            : `We’ve sent verification code to ${user.phone}`}
        </p>
        {isVerified ? (
          <div className="flex flex-col items-center mt-5">
            <SignatureCanvas
              ref={sigCanvasRef}
              canvasProps={{
                width: 550,
                height: 200,
                className: 'border border-gray-400 rounded-md',
              }}
            />
            <div
              className="mt-2 text-red-500 text-center cursor-pointer"
              onClick={() => sigCanvasRef.current.clear()}
            >
              Clear
            </div>
            <div className="flex mt-1">
              <Checkbox onChange={(e) => setIsAccepted(e.target.checked)} />
              &nbsp;&nbsp;I accept&nbsp;
              <div
                className="text-main-green underline cursor-pointer"
                onClick={downloadTermsAndConditions}
              >
                Terms and Conditions
              </div>
            </div>
            <div className="mt-5 w-80">
              <Button
                loading={isUploading}
                disabled={!isAccepted}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </div>
        ) : (
          <div className="mt-5">
            <OtpInput
              containerStyle="flex gap-x-2"
              value={code}
              numInputs={6}
              renderInput={(props) => (
                <input
                  {...props}
                  className="w-12 h-14 text-lg rounded-lg"
                />
              )}
              inputStyle={{ width: 48 }}
              shouldAutoFocus
              onChange={(value) => setCode(value)}
            />
            <div className="flex flex-col mt-5 w-80">
              <Button
                loading={isVerifying}
                disabled={code.length !== 6}
                onClick={handleVerify}
              >
                Confirm
              </Button>
              <div
                className="mt-2 text-sm text-center cursor-pointer hover:underline"
                onClick={handleRequestCode}
              >
                Resend
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Verify;
