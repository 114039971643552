/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { BsCheck2, BsPencil, BsTrash, BsX } from 'react-icons/bs';
import clsx from 'clsx';

import Button from 'components/Button';
import Input from 'components/Input';
import { AppointmentType } from 'utils/labels';

import { ReactComponent as StethoscopeIcon } from 'assets/icons/stethoscope.svg';

const Services = ({ user }) => {
  const [selectedServiceItem, setSelectedServiceItem] = useState({
    service: '',
    duration: 15,
    price: 0,
    type: AppointmentType.VIRTUAL,
  });
  const [availableTypes, setAvailableTypes] = useState([]);
  const [isItemEdit, setIsItemEdit] = useState(false);
  const [showNewServiceItemModal, setShowNewServiceItemModal] = useState(false);

  const getAppointmentType = (type) => {
    switch (type) {
      case AppointmentType.VIRTUAL:
        return 'Virtual visit';
      case AppointmentType.PROVIDER_LOCATION:
        return 'Clinic visit';
      case AppointmentType.CUSTOMER_LOCATION:
        return 'Mobile visit';
      default:
        return '';
    }
  };

  const updateServiceName = (previousServiceName, newName) => {
    const index = user.services.findIndex((sv) => sv.name === previousServiceName);
    const newServices = [...user.services];
    newServices[index].name = newName;
    // updateServices([...newServices]);
  };

  const removeService = (service) => {
    // eslint-disable-next-line no-unused-vars
    const updatedServices = user.services.filter((sv) => sv.name !== service.name);
    // updateServices([...updatedServices]);
  };

  const ServiceElement = ({ service }) => {
    const inputRef = useRef(null);
    const [isEdit, setIsEdit] = useState(false);
    const [newName, setNewName] = useState(service.name);
    const [showActions, setShowActions] = useState(false);

    const serviceItems = useMemo(() => {
      if (service.items) {
        return service.items.reduce((acc, item) => {
          const { type } = item;
          if (!acc[type]) {
            acc[type] = [];
          }
          acc[type].push(item);
          return acc;
        }, {});
      }
      return {};
    }, [service]);

    useEffect(() => {
      if (isEdit) {
        setTimeout(() => {
          inputRef.current?.focus();
        }, 200);
      }
    }, [isEdit]);
    console.log(serviceItems);
    if (!service) {
      return null;
    }

    return (
      <div
        key={service.name}
        className="flex flex-row w-full"
      >
        <div
          className="w-[350px] flex items-center justify-between py-1 px-2.5 border-r border-b border-[#E3E3E3]"
          onMouseEnter={() => setShowActions(true)}
          onMouseLeave={() => setShowActions(false)}
        >
          {isEdit ? (
            <>
              <input
                className="py-1 px-2 text-sm border border-main-gray focus:border-main-green focus:outline-none rounded-lg"
                ref={inputRef}
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
              />
              <div className="flex items-center gap-x-1 ml-2">
                <span
                  className="text-xl cursor-pointer"
                  onClick={() => {
                    setIsEdit(false);
                    updateServiceName(service.name, newName);
                  }}
                >
                  <BsCheck2 color="#08A284" />
                </span>
                <span
                  className="text-xl cursor-pointer"
                  onClick={() => {
                    setIsEdit(false);
                    setNewName(service.name);
                  }}
                >
                  <BsX color="#dc2626" />
                </span>
              </div>
            </>
          ) : (
            <>
              <p className="p-1 font-semibold">{service?.name}</p>
              <div
                className={clsx('flex items-center gap-x-1', {
                  hidden: !showActions,
                  block: showActions,
                })}
              >
                <span
                  className="text-sm cursor-pointer"
                  onClick={() => setIsEdit(true)}
                >
                  <BsPencil />
                </span>
                <span
                  className="text-sm cursor-pointer"
                  onClick={() => removeService(service)}
                >
                  <BsTrash color="#dc2626" />
                </span>
              </div>
            </>
          )}
        </div>
        <div className="w-full">
          {[
            AppointmentType.VIRTUAL,
            AppointmentType.PROVIDER_LOCATION,
            AppointmentType.CUSTOMER_LOCATION,
          ].map((atype) => (
            <div key={`appointment-type-${atype}`}>
              {serviceItems[atype] ? (
                <div className="flex flex-row">
                  <div className="basis-1/4 p-2.5 border-r border-b border-[#E3E3E3]">
                    {getAppointmentType(atype)}
                  </div>
                  {serviceItems[atype]
                    .sort((a, b) => a.duration - b.duration)
                    .map((it, index) => (
                      <div
                        key={`${service.name}-${index}`}
                        className="flex-1 flex flex-row items-center"
                        onClick={() => {
                          const similarItems = service.items?.filter(
                            (item) => item.duration === it.duration && item.type !== it.type,
                          );
                          const tempTypes = [
                            AppointmentType.VIRTUAL,
                            AppointmentType.PROVIDER_LOCATION,
                            AppointmentType.CUSTOMER_LOCATION,
                          ].filter((tp) => !similarItems?.find((item) => item.type === tp));
                          setAvailableTypes([...tempTypes]);
                          setSelectedServiceItem({
                            service: service.name,
                            duration: it.duration,
                            price: it.price,
                            type: it.type,
                          });
                          setIsItemEdit(true);
                          setShowNewServiceItemModal(true);
                        }}
                      >
                        <div className="basis-1/3 p-2.5 border-r border-b border-[#E3E3E3]">
                          {`${
                            Math.floor(it.duration / 60) > 0
                              ? `${Math.floor(it.duration / 60)}h`
                              : ''
                          }${it.duration % 60 ? ` ${it.duration % 60}m` : ''}`}
                        </div>
                        <div className="basis-1/3 p-2.5 text-main-green border-r border-b border-[#E3E3E3]">{`$${it.price.toFixed(
                          2,
                        )}`}</div>
                        <div className="basis-1/3 flex items-center justify-center gap-x-4 p-[0.85rem] border-b border-[#E3E3E3]">
                          <div className="cursor-pointer">
                            <BsPencil color="#0891b2" />
                          </div>
                          <div className="cursor-pointer">
                            <BsTrash color="#dc2626" />
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              ) : null}
            </div>
          ))}
          <div className="flex items-center justify-center gap-x-2 p-2.5 border-b border-[#E3E3E3]">
            {Object.keys(serviceItems).length === 0 ? <p>No service item</p> : null}
            <p className="text-main-green underline cursor-pointer">Add New</p>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="p-8 h-full">
      <p className="text-2xl font-semibold">Services</p>
      <div className="mt-5 p-5 bg-[#EFEFEF] rounded-xl">
        <div className="relative">
          <Input
            Icon={({ className }) => <StethoscopeIcon className={`${className} mx-1`} />}
            placeholder="Type and add service..."
          />
          <Button
            rounded={false}
            cssClass="absolute top-1.5 right-2 text-sm"
          >
            Add
          </Button>
        </div>
        <div className="flex flex-col mt-5 w-full bg-white overflow-x-auto rounded-xl">
          {user.services.map((service, index) => (
            <section
              key={index}
              className="w-full"
            >
              <ServiceElement service={service} />
            </section>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
