import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Input from 'components/Input';
import Button from 'components/Button';
import Select from 'components/Select';

import logoImg from 'assets/logo.png';

const roles = [
  { value: 'customer', label: 'Customer' },
  { value: 'provider', label: 'Provider' },
  { value: 'clinic-admin', label: 'Clinic' },
];

const Register = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    const phoneNumber = data.get('phone').replace(/[^A-Z0-9]+/ig, '');
    const password = data.get('password');
    const confirmPassword = data.get('confirmPassword');
    const firstName = data.get('firstName');
    const lastName = data.get('lastName');
    const role = data.get('role');

    if (
      !role ||
      !firstName ||
      (role !== 'clinic' && !lastName) ||
      !phoneNumber ||
      phoneNumber.length < 10 ||
      !password ||
      !confirmPassword
    ) {
      return setError('Please add correct information');
    }
    if (password !== confirmPassword) {
      return setError('Password not matched');
    }

    setIsLoading(true);
  };

  return (
    <div className="flex h-screen">
      <div className="flex flex-col m-auto items-center w-full max-w-md">
        <img
          src={logoImg}
          className="w-40 h-12 cursor-pointer"
          onClick={() => navigate('/')}
        />
        <p className="mt-5 text-4xl font-semibold">Sign Up</p>
        <p className="mt-5 text-base text-gray-500 text-center">
          Please enter information about you
        </p>
        <form
          className="flex flex-col gap-y-2 mt-5 w-full"
          onSubmit={handleSubmit}
        >
          {error ? <span className="text-sm text-center text-red-500">{error}</span> : null}
          <Select
            name="role"
            options={roles}
            placeholder="Please select role"
          />
          <Input
            name="phone"
            type="phone"
            placeholder="Phone number"
          />
          <Input
            name="firstName"
            type="text"
            placeholder="First Name"
          />
          <Input
            name="lastName"
            type="text"
            placeholder="Last Name"
          />
          <Input
            name="password"
            type="password"
            placeholder="Password"
          />
          <Input
            name="confirmPassword"
            type="password"
            placeholder="Confirm Password"
          />
          <Button
            fullWidth
            loading={isLoading}
            cssClass="mt-4"
            type="submit"
            variant="contained"
          >
            Sign Up
          </Button>
          <div className="flex gap-x-2 justify-center mt-2">
            <p className="text-base text-gray-500">{'Already have an account?'}</p>
            <p
              className="text-main-green underline underline-offset-2 cursor-pointer"
              onClick={() => navigate('/login')}
            >
              Sign In
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
