import React from 'react';

import Button from 'components/Button';
import Toggle from 'components/Toggle';

const Notification = () => {
  return (
    <div className="p-8 h-full">
      <p className="text-2xl font-semibold">Notifications</p>
      <div className="flex flex-col gap-y-4 mt-4">
        <div className="py-5 px-5 bg-white rounded-xl">
          <p className="text-lg font-medium">Manage communication settings</p>
          <p className="text-sm">
            Please enable browser notifications to get notified of events, i.e. new consultation
            message
          </p>
          <Button
            rounded={false}
            cssClass="mt-6"
          >
            Enable Browser Notifications
          </Button>
        </div>
        <div className="py-5 px-5 bg-white rounded-xl">
          <p className="text-lg font-medium">Notification preferences</p>
          <p className="text-sm">
            Customize your notification settings for consultation updates and massages, security
            alerts and referral alerts
          </p>
          <div className="flex flex-col gap-y-3 mt-8 w-full">
            <div className="flex flex-row items-center">
              <div className="basis-1/2" />
              <div className="flex-1 flex flex-row">
                <div className="basis-1/4 text-main-green font-semibold">In App</div>
                <div className="basis-1/4 text-main-green font-semibold">Email</div>
                <div className="basis-1/4 text-main-green font-semibold">SMS</div>
                <div className="basis-1/4 text-main-green font-semibold">Push</div>
              </div>
            </div>
            <div className="flex flex-row items-center">
              <div className="basis-1/2 font-medium">Consultation updates</div>
              <div className="flex-1 flex flex-row">
                <div className="basis-1/4">
                  <Toggle defaultChecked />
                </div>
                <div className="basis-1/4">
                  <Toggle />
                </div>
                <div className="basis-1/4">
                  <Toggle />
                </div>
                <div className="basis-1/4">
                  <Toggle />
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center">
              <div className="basis-1/2 font-medium">New messages on consultations</div>
              <div className="flex-1 flex flex-row">
                <div className="basis-1/4" />
                <div className="basis-1/4">
                  <Toggle />
                </div>
                <div className="basis-1/4">
                  <Toggle />
                </div>
                <div className="basis-1/4">
                  <Toggle />
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center">
              <div className="basis-1/2 font-medium">Security alerts</div>
              <div className="flex-1 flex flex-row">
                <div className="basis-1/4">
                  <Toggle defaultChecked />
                </div>
                <div className="basis-1/4">
                  <Toggle />
                </div>
                <div className="basis-1/4">
                  <Toggle />
                </div>
                <div className="basis-1/4">
                  <Toggle />
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center">
              <div className="basis-1/2 font-medium">Referral alerts</div>
              <div className="flex-1 flex flex-row">
                <div className="basis-1/4" />
                <div className="basis-1/4">
                  <Toggle />
                </div>
                <div className="basis-1/4" />
                <div className="basis-1/4" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
