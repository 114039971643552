import React from 'react';
import ReactSelect from 'react-select';

const Select = ({ name, value, placeholder, options, onChange }) => {
  const colorStyles = {
    control: (styles) => ({
      ...styles,
      height: 44,
      fontSize: 16,
      borderColor: '#E7E7E7',
      backgroundColor: '#FFF',
      borderRadius: 10,
      ':hover': {
        borderColor: '#08A284',
      },
    }),
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      color: '#9ca3af',
      fontSize: 16,
    }),
    option: (styles, { isDisabled }) => {
      return {
        ...styles,
        color: isDisabled ? '#ccc' : 'black',
        cursor: isDisabled ? 'not-allowed' : 'default',
      };
    },
  };

  return (
    <ReactSelect
      styles={colorStyles}
      name={name}
      value={value}
      options={options}
      components={{
        IndicatorSeparator: () => null,
      }}
      isSearchable={false}
      theme={(theme) => ({
        ...theme,
        borderRadius: 10,
        colors: {
          ...theme.colors,
          primary25: '#FBFBFB',
          primary: '#D7F0EC',
        },
      })}
      placeholder={placeholder}
      onChange={(val) => onChange?.(val)}
    />
  );
};

export default Select;
