import React, { createRef } from 'react';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';

import Button from 'components/Button';

import { ReactComponent as FileCheckIcon } from 'assets/icons/file_check.svg';

const Media = () => {
  const dropzoneRef = createRef();

  return (
    <div className="p-8 h-full">
      <p className="text-2xl font-semibold">Photos</p>
      <div className="flex flex-col gap-y-4 mt-4">
        <Dropzone
          ref={dropzoneRef}
          onDrop={(files, errors) => {
            if (errors?.length > 0) {
              toast('Please upload image files.', { type: 'error' });
            }
            if (files.length) {
              // TODO
              console.log(files);
            }
          }}
          accept={{
            'image/*': ['.jpeg', '.png'],
          }}
          disabled={false}
        >
          {({ getRootProps }) => (
            <section>
              <div
                {...getRootProps()}
                className="flex flex-col items-center gap-y-3 mt-6 p-8 bg-white border border-dashed border-[#D2D2D2] rounded-xl"
              >
                <FileCheckIcon />
                <p className="text-[#374957] font-medium">Drag/Drop file here</p>
                <div className="text-center">
                  <p className="text-sm leading-2">
                    You should upload media files based on guidance of Terms and Conditions.
                  </p>
                  <p className="text-sm leading-2">
                    Now only <strong>JPEG, ONG, Mp4</strong> files are available. Each file size
                    should be <strong>less than 200MB</strong> and you can’t upload more than{' '}
                    <strong>10 files</strong>
                  </p>
                </div>
              </div>
            </section>
          )}
        </Dropzone>
        <div className="flex items-center gap-x-4">
          <Button rounded={false}>Select all</Button>
          <Button
            rounded={false}
            color="dark"
            variant="outlined"
          >
            Unselect all
          </Button>
          <Button
            rounded={false}
            color="danger"
          >
            Delete
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Media;
